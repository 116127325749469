import React, { useState, useEffect } from 'react';
import { Link, Divider } from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';

import { TagType } from 'mosaic/enums';

import api from '../api';

import TagTable from './TagTable';
import DeleteTagDialog from './DeleteTagDialog';
import CreateTag from './CreateTag';
import './TagList.css';

const DefaultTagManagement = ({ showSnack }) => {
  const type = TagType.DEFAULT;

  const [tags, setTags] = useState([]);
  const [tagDeleteId, setTagDeleteId] = useState(null);

  const createTag = async e => {
    const name = e.target.value.trim();
    if (!name) return;

    // error handled by the caller
    const createdTag = await api.post('tags/admin', { name, type });
    setTags([...tags, createdTag]);
    showSnack(`Tag "${name}" created`);
  };

  const tagToDelete = tags.find(tag => tag._id === tagDeleteId);
  const deleteTag = async () => {
    try {
      await api.delete(`tags/${tagDeleteId}/admin`);
      showSnack('Tag deleted');
      const updatedTags = tags.filter(tag => tag._id !== tagDeleteId);
      setTags(updatedTags);
    } catch (err) {
      showSnack(`ERROR: ${err.message}`);
    } finally {
      setTagDeleteId(null);
    }
  };

  useEffect(() => {
    const getTags = async () => {
      const defaultTags = await api.tags.getAll({ type });
      setTags(defaultTags);
    };

    getTags();
  }, []);

  return (
    <div className="tag-settings">
      <CreateTag createTag={createTag} />

      <Divider style={{ width: '100%', margin: '30px auto' }} />

      <Link href="https://tiledinstance.looker.com/dashboards/189" rel="noopener" target="_blank">
        <strong>See analtyics on default tags</strong> <OpenInNewOutlined className="icon" />
      </Link>

      <Divider style={{ width: '100%', margin: '30px auto' }} />

      {!tags.length ? (
        <p>No default tags created</p>
      ) : (
        <TagTable tags={tags} onDelete={tagId => setTagDeleteId(tagId)} />
      )}

      {tagToDelete && <DeleteTagDialog tag={tagToDelete} onClose={() => setTagDeleteId(null)} onDelete={deleteTag} />}
    </div>
  );
};

export default DefaultTagManagement;
