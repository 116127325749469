import reqwest from 'reqwest';

import api from 'mosaic';

const getHelper = (storageDB, key, optDefault) => {
  key = `tiled_${key}`;
  if (key in storageDB) {
    return JSON.parse(storageDB[key]);
  }
  return optDefault;
};

const setHelper = (storageDB, key, data) => {
  key = `tiled_${key}`;
  storageDB[key] = JSON.stringify(data);
  return data;
};

export const storage = {
  local: {
    get: (key, optDefault) => {
      return getHelper(localStorage, key, optDefault);
    },
    set: (key, data) => {
      return setHelper(localStorage, key, data);
    }
  },
  session: {
    get: (key, optDefault) => {
      return getHelper(sessionStorage, key, optDefault);
    },
    set: (key, data) => {
      return setHelper(sessionStorage, key, data);
    }
  }
};

export const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:3002/v2';

api.config({
  ajaxer: reqwest,
  store: {
    getItem: key => {
      return Promise.resolve(storage.local.get(key));
    },
    setItem: (key, value) => {
      return Promise.resolve(storage.local.set(key, value));
    }
  },
  baseUrl
});

export default api;
