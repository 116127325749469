import React from 'react';
import _ from 'lodash';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';

const SelectField = props => {
  const { label, value, options = [], onSelect = () => {}, disabled = true, multiple = false, ...rest } = props;

  return (
    <FormControl fullWidth {...rest}>
      <InputLabel id="select" shrink sx={{ bgcolor: 'white', paddingRight: '8px' }}>
        {label}
      </InputLabel>
      <Select
        fullWidth
        notched
        size="small"
        multiple={multiple}
        labelId="select"
        defaultValue=""
        value={value}
        input={<OutlinedInput label={label} disabled={disabled} size="small" sx={{ fontSize: '13px' }} />}
      >
        {_.map(options, option => (
          <MenuItem sx={{ fontSize: '13px' }} key={option.value} value={option.value} onClick={() => onSelect(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
