import React from 'react';
import { ButtonContainer, VStack, Flex } from 'tiled-ui';

const LeftSideNavBar = props => {
  const { sections, onChange, currentView } = props;

  return (
    <VStack bg="blue.60" w="15%" p="5" spacing="3">
      {sections.map(section => {
        const isCurrentView = section.key === currentView;

        return (
          <Flex
            key={section.name}
            w="100%"
            borderRadius="round"
            aria-selected={isCurrentView}
            _selected={{ bg: 'white' }}
          >
            <ButtonContainer
              w="100%"
              variant={isCurrentView ? 'text' : 'primary'}
              leftIcon={section.icon}
              onClick={() => onChange(section.key)}
            >
              {section.name}
            </ButtonContainer>
          </Flex>
        );
      })}
    </VStack>
  );
};

export default LeftSideNavBar;
