import React from 'react';
import { FormGroup, FormControlLabel, Checkbox as MUICheckbok } from '@mui/material';

const Checkbox = props => {
  const { defaultChecked, disabled, required, onChange, label } = props;
  return (
    <FormGroup>
      <FormControlLabel
        disabled={disabled}
        required={required}
        control={<MUICheckbok defaultChecked={defaultChecked} onChange={onChange} size="small" />}
        label={label}
        componentsProps={{ typography: { variant: 'subtitle2', fontWeight: 'normal' } }}
      />
    </FormGroup>
  );
};

export default Checkbox;
