import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './App.css';

import Login from './Login';
import Nav from './Nav';
import Tools from './Tools';
import Settings from './Settings';
import AllAccounts from './AllAccounts/index';
import Super from './Super';
import AuditPage from './AuditPage';
import { storage } from './api';
import { TiledUIProvider } from 'tiled-ui';

function App() {
  const user = storage.local.get('user');
  const [values, setValues] = React.useState({
    snackOpen: false,
    snackMsg: ''
  });

  const [loggedIn, setLoggedIn] = React.useState(!!user);

  function showSnack(msg) {
    setValues({ ...values, snackOpen: true, snackMsg: msg });
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setValues({ ...values, snackOpen: false });
  }

  function onSignOut(event) {
    storage.local.set('user', null);
    window.location.pathname = '/';
  }

  function onLogin() {
    setLoggedIn(true);
  }

  return (
    <div className="App">
      <div className="App-body">
        <TiledUIProvider>
          <Router>
            {loggedIn && <Nav name={user && user.name} loggedIn={loggedIn} onSignOut={onSignOut} user={user} />}
            <Switch>
              <Route path="/tools" render={props => <Tools {...props} showSnack={showSnack} />} />
              <Route path="/settings" render={props => <Settings {...props} showSnack={showSnack} />} />
              <Route path="/accounts" render={props => <AllAccounts {...props} showSnack={showSnack} />} />
              <Route path="/super" render={props => <Super {...props} showSnack={showSnack} user={user} />} />
              <Route path="/audit" render={props => <AuditPage {...props} showSnack={showSnack} user={user} />} />
              <Route path="/" render={props => <Login {...props} showSnack={showSnack} onLogin={onLogin} />} />
            </Switch>
          </Router>
          <Snackbar
            style={{ zIndex: 1500 }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={values.snackOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
            message={<span id="message-id">{values.snackMsg}</span>}
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ]}
          />
        </TiledUIProvider>
      </div>
    </div>
  );
}

export default App;
