import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import api from '../api';
import permissions from 'mosaic/permissions';

import PermissionDeniedPage from '../Super/PermissionDeniedPage';
import LogTableFilter from './LogTableFilter';
import { Box, Container, Typography } from '@mui/material';
import LogTable from './LogTable';
import { formatAuditData } from './lib/util';

const PAGE_SIZE = 20;

const AuditPage = props => {
  const { user } = props;

  const [filter, setDataFilter] = useState({});
  const [data, setData] = useState({});

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const searchParams = new URLSearchParams({ ...filter, page: currentPage + 1, limit: PAGE_SIZE });
      const queryString = searchParams.toString();
      const data = await api.fetch(`audit?${queryString}`);
      const formatData = formatAuditData(data.results);

      setData({ ...data, results: formatData });
      setIsLoading(false);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage, filter]);

  if (!permissions.isSuperUser(user)) return <PermissionDeniedPage />;

  const onSetFilter = (key, value) => {
    const filterObj = _.cloneDeep(filter);
    if (!Array.isArray(value) && !value) delete filterObj[key];
    else filterObj[key] = value;

    if (key === 'accountName') delete filterObj.accountId;

    setDataFilter(filterObj);
  };

  console.log('data', data);

  return (
    <Container maxWidth="xl" disableGutters sx={{ maxHeight: '100vh', overflow: 'hidden' }}>
      <Box padding="20px" position="sticky" top={0} bgcolor="white" zIndex="appBar">
        <Typography textAlign="left" variant="h5" fontWeight="bold">
          Audit Log
        </Typography>
        <LogTableFilter filter={filter} onSetFilter={onSetFilter} />
      </Box>
      <LogTable
        rows={data.results || []}
        rowCount={data.count || 0}
        paginationModel={{ page: currentPage, pageSize: PAGE_SIZE }}
        setPaginationModel={pagination => setCurrentPage(pagination.page)}
        isLoading={isLoading}
        limitSize={PAGE_SIZE}
      />
    </Container>
  );
};

export default AuditPage;
