import React, { useState, useRef } from 'react';
import { FormControl, TextField } from '@mui/material';

const InputField = props => {
  const { id, label, value, onBlur: onBlurInput = () => {}, disabled = true, clearAfterBlur = false, ...rest } = props;

  const inputRef = useRef(null);
  const [newValue, setNewValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = e => {
    setNewValue(e.target.value);
    if (errorMessage) setErrorMessage('');
  };

  const onBlur = async e => {
    try {
      if (!clearAfterBlur) return onBlurInput(e);
      await onBlurInput(e);
      setNewValue('');
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <FormControl fullWidth>
      <TextField
        ref={inputRef}
        error={!!errorMessage}
        helperText={errorMessage}
        disabled={disabled}
        id={`${id}-input-field`}
        margin="normal"
        label={label}
        variant="outlined"
        size="small"
        defaultValue={value}
        value={newValue}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
      />
    </FormControl>
  );
};

export default InputField;
