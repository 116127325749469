import React from 'react';
import _ from 'lodash';
import { VStack, HStack, TextContainer, InputContainer } from 'tiled-ui';
import { isObjectType } from './util';

const Wrapper = ({ inline, children, depth }) => {
  const pl = depth * 3;

  if (inline) return <HStack pl={pl}>{children}</HStack>;
  return (
    <VStack pl="4" alignItems="flex-start">
      {children}
    </VStack>
  );
};

const ValueWrapper = ({ previewMode, value, fontFamily }) => {
  return previewMode ? (
    <TextContainer textStyle="textXS" fontWeight="medium" fontFamily={fontFamily}>
      : {value}
    </TextContainer>
  ) : (
    <InputContainer isDisabled size="xs" value={value} />
  );
};

const KeyValue = props => {
  const { value, dataKey, depth = 1, previewMode = false } = props;

  const inline = !isObjectType(value);
  const fontFamily = previewMode ? 'Courier New' : null;

  return (
    <Wrapper inline={inline} depth={depth}>
      <TextContainer textStyle="textXS" fontWeight="medium" fontFamily={fontFamily}>
        {dataKey}
      </TextContainer>

      {!inline ? (
        _.map(value, (v, k) => <KeyValue value={v} dataKey={k} key={k} depth={depth + 1} previewMode={previewMode} />)
      ) : (
        <ValueWrapper previewMode={previewMode} value={value} fontFamily={fontFamily} />
      )}
    </Wrapper>
  );
};

export default KeyValue;
