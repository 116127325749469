import React from 'react';
import { ButtonContainer, ModalContainer, TextContainer, VStack } from 'tiled-ui';

const DeleteUserModal = props => {
  const { onClose, onDelete, user } = props;

  return (
    <ModalContainer isOpen onClose={onClose}>
      <ModalContainer.Header>Delete user</ModalContainer.Header>
      <ModalContainer.Body>
        <VStack spacing="2" alignItems="flex-start">
          <TextContainer>
            Are you sure you want to delete this user?
            <br />
            <strong>{user.email}</strong>
          </TextContainer>
        </VStack>
      </ModalContainer.Body>
      <ModalContainer.Footer>
        <ButtonContainer variant="secondary" onClick={onClose} mr="3">
          Cancel
        </ButtonContainer>
        <ButtonContainer variant="error" onClick={onDelete}>
          Delete
        </ButtonContainer>
      </ModalContainer.Footer>
    </ModalContainer>
  );
};

export default DeleteUserModal;
