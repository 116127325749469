import React, { useState } from 'react';
import { Flex, Container } from 'tiled-ui';

import permissions from 'mosaic/permissions';
import { FiUsers, FiCodesandbox, FiSettings } from 'react-icons/fi';
import LeftSideNavBar from './LeftSideNavBar';
import Users from './Users';
import RoleSandbox from './RoleSandbox';
import ConfigSettings from './ConfigSettings';
import PermissionDeniedPage from './PermissionDeniedPage';

const USERS = 'users';
const ROLESANDBOX = 'rolessandbox';
const CONFIG_SETTINGS = 'config-settings';

const Super = props => {
  const { user, showSnack } = props;
  const [currentView, setCurrentView] = useState(CONFIG_SETTINGS);

  if (!permissions.isSuperUser(user)) {
    return <PermissionDeniedPage />;
  }

  const sections = [
    { key: USERS, name: 'Users', icon: FiUsers },
    { key: CONFIG_SETTINGS, name: 'Config Settings', icon: FiSettings },
    { key: ROLESANDBOX, name: 'Roles Sandbox', icon: FiCodesandbox }
  ];

  return (
    <Flex w="100%" minH="calc(100vh - 75px)">
      <LeftSideNavBar sections={sections} onChange={view => setCurrentView(view)} currentView={currentView} />
      <Container m="7" maxW="100%">
        {currentView === USERS && <Users showSnack={showSnack} />}
        {currentView === ROLESANDBOX && <RoleSandbox showSnack={showSnack} />}
        {currentView === CONFIG_SETTINGS && <ConfigSettings showSnack={showSnack} />}
      </Container>
    </Flex>
  );
};

export default Super;
