import React from 'react';
import _ from 'lodash';
import { Box, Typography, List, ListItem, ListItemText, ListSubheader, Checkbox } from '@mui/material';

const ListComponent = props => {
  const { subHeader, listItemMap, data, children, style } = props;

  return (
    <List dense className="list" style={style}>
      {subHeader && (
        <ListSubheader align="left" className="list-sub-header">
          {subHeader()}
        </ListSubheader>
      )}

      {_.map(listItemMap, (itemFormatValue, itemKey) => {
        const pendingValue = data[itemKey];
        const value = itemFormatValue
          ? itemFormatValue(pendingValue)
          : typeof pendingValue === 'boolean'
          ? JSON.stringify(pendingValue)
          : pendingValue || '';

        return (
          <ListItem key={itemKey}>
            <div className="list-item-detail-key">
              {_.startCase(itemKey)}: <span className="list-item-detail-value">{value}</span>
            </div>
          </ListItem>
        );
      })}

      {children}
    </List>
  );
};

export const ListSelectComponent = props => {
  const { itemList, selectedItem, onSelect, displayKey } = props;
  return (
    <List dense>
      {(itemList || []).map(item => {
        const itemId = item._id;
        const className = selectedItem._id === itemId ? 'list-item-selected' : 'list-item-pointer';

        return (
          <ListItem key={itemId} className={className} onClick={() => onSelect(item)}>
            <div className="list-item-detail-value">{item[displayKey]}</div>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ListComponent;
