const methodMap = {
  POST: 'created',
  PATCH: 'updated',
  DELETE: 'deleted'
};

export const formatAuditData = data => {
  return data.map(d => {
    const { action, httpMethod: method = '' } = d;
    const eventAction = action.type || methodMap[method] || method;
    const actionType = `${eventAction} ${d.type}`;

    const requestUrl = (d.expressBaseUrl + d.expressPath || '').replace('/v2', '');

    return {
      ...d,
      auditId: d._id,
      method,
      timestamp: d.reqEndTime ? new Date(d.reqEndTime).toISOString() : '',
      requestUrl,
      originalUrl: d.httpMethod + ' ' + requestUrl,
      assumedRole: JSON.stringify(d.assumedRole),
      affectedAccountName: action?.accountName || action?.userAccountName,
      affectedAccountId: action?.accountId || action?.userAccountId,
      actionType
    };
  });
};

export const actorKeys = [
  'actorId',
  'actorName',
  'actorEmail',
  'actorAccountId',
  'actorAccountName',
  'assumedId',
  'assumedName',
  'assumedEmail',
  'assumedAccountId',
  'assumedAccountName'
];
export const eventKeys = [
  'userId',
  'userName',
  'userEmail',
  'originalUrl',
  'userAccountId',
  'userAccountName',
  'accountId',
  'accountName'
];
export const metadataKeys = ['auditId', 'timestamp', 'hostname', 'apiVersion', 'tenantId', 'ipAddress'];
