import React, { useState } from 'react';
import { TextContainer, ModalContainer, ButtonContainer } from 'tiled-ui';

import UserDetails from './UserDetails';
import DeleteUserModal from './DeleteUserModal';
import api from '../api';

const UserEditModal = props => {
  const { user, isOpen, onClose, isDisabled = true, showSnack, fetchUsers } = props;
  const prefix = isDisabled ? 'View' : 'Edit';

  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const onCloseDeleteUserModal = () => setShowDeleteUserModal(false);

  const onDelete = async () => {
    await api.users(user._id).delete();
    fetchUsers();
    onCloseDeleteUserModal();
    onClose();
  };

  return (
    <>
      <ModalContainer isOpen={isOpen} onClose={onClose} size="3xl" isCentered disableExitBtn>
        <ModalContainer.Header display="flex" justifyContent="space-between" alignItems="center">
          <TextContainer textStyle="headingMd" fontWeight="extrabold">
            {prefix} User: {user.name}
          </TextContainer>

          <ButtonContainer
            variant="errorText"
            onClick={setShowDeleteUserModal.bind(this, true)}
          >
            Delete User
          </ButtonContainer>
        </ModalContainer.Header>

        <ModalContainer.Body pb="6">
          <UserDetails user={user} isDisabled={isDisabled} showSnack={showSnack} />
        </ModalContainer.Body>
      </ModalContainer>
      {showDeleteUserModal && <DeleteUserModal onClose={onCloseDeleteUserModal} onDelete={onDelete} user={user} />}
    </>
  );
};

export default UserEditModal;
