import React from 'react';
import { Box, TextContainer } from 'tiled-ui';

const PermissionDeniedPage = () => {
  return (
    <Box mt="20">
      <TextContainer textStyle="heading2XL" fontWeight="extrabold">
        You are not James!!{' '}
        <span role="img" aria-labelledby="superman">
          🦸
        </span>
      </TextContainer>
    </Box>
  );
};

export default PermissionDeniedPage;
