import React from 'react';
import { Box, PopoverContainer, ButtonContainer } from 'tiled-ui';
import UserPermissions from '../components/Permissions';
import _ from 'lodash';

const PermissionsPopover = props => {
  const { triggerLabel, showOnlyAdmin = false, showAdmin = false, visibility = 'visible' } = props;
  const permissions = _.pick(props, ['permissions', 'groupPermissions', 'adminPermissions']);

  return (
    <PopoverContainer
      isLazy
      trigger="hover"
      placement="right-end"
      renderArrow
      contentProps={{
        padding: '8px',
        backgroundColor: 'gray.10',
        border: '1px solid',
        borderColor: 'gray.40',
        visibility
      }}
      triggerElement={
        <ButtonContainer visibility={visibility} size="xs" variant="textOnly" textStyle="textXXS">
          {triggerLabel}
        </ButtonContainer>
      }
      content={
        <Box>
          <UserPermissions {...permissions} reduceScale showOnlyAdmin={showOnlyAdmin} showAdmin={showAdmin} />
        </Box>
      }
    />
  );
};

export default PermissionsPopover;
