import React, { useState, useEffect } from 'react';
import { HStack, VStack, TextContainer, Checkbox, Spinner } from 'tiled-ui';
import _ from 'lodash';

import SearchField from '../components/Form/SearchField';

import api from '../api';
import UserEditModal from './UserEditModal';

const Users = ({ showSnack }) => {
  const [shouldSearchAllSuperUsers, setShouldAllSuperUsers] = useState(false);
  const [userSearch, setUserSearch] = useState('');

  const [isFetching, setIsFetching] = useState(true);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const hasSearchValue = userSearch.trim() || shouldSearchAllSuperUsers;
  const noResult = !isFetching && hasSearchValue && !searchedUsers.length;

  const fetchUsers = async () => {
    if (!isFetching) setIsFetching(true);

    let url = 'users/admin?';
    if (userSearch) url += `user=${userSearch}&`;
    if (shouldSearchAllSuperUsers) url += `isSuper=true`;

    const users = await api.fetch(url);
    setSearchedUsers(users);
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        if (!hasSearchValue) {
          if (searchedUsers.length) setSearchedUsers([]);
          return;
        }

        if (!selectedUser) await fetchUsers();
      } catch (err) {
        showSnack('Error fetching users. Open console for error.');
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    };

    getUsers();
  }, [userSearch, shouldSearchAllSuperUsers, selectedUser]);

  return (
    <VStack id="users-section" alignItems="flex-start">
      <HStack id="user-search" spacing="7" w="100%" justifyContent="space-between">
        <SearchField
          label={'Search user by name or email'}
          onChange={_.debounce(e => setUserSearch(e.target.value), 500)}
        />
        <Checkbox
          w="50%"
          alignItems="flex-end"
          isChecked={shouldSearchAllSuperUsers}
          onChange={e => setShouldAllSuperUsers(e.target.checked)}
        >
          <TextContainer textStyle="textXS">Search all superusers</TextContainer>
        </Checkbox>
      </HStack>
      {isFetching && <Spinner mt="5" speed="0.7s" color="gray.40" />}
      {noResult && (
        <TextContainer textStyle="textSm" fontWeight="medium">
          No results found.
        </TextContainer>
      )}

      {!noResult &&
        searchedUsers.map(user => {
          return (
            <HStack
              key={user._id}
              spacing="4"
              w="80%"
              h="7"
              p="4px 16px"
              cursor="pointer"
              borderRadius="round"
              _hover={{ bgColor: 'blue.20' }}
              onClick={() => setSelectedUser(user)}
            >
              <TextContainer textStyle="textSm" fontWeight="extrabold">
                {user.name}
              </TextContainer>

              <TextContainer textStyle="textSm" fontWeight="medium">
                {user.email}
              </TextContainer>
            </HStack>
          );
        })}

      {!!selectedUser && (
        <UserEditModal
          isOpen={selectedUser}
          onClose={() => setSelectedUser(null)}
          user={selectedUser}
          isDisabled={false}
          showSnack={showSnack}
          fetchUsers={fetchUsers}
        />
      )}
    </VStack>
  );
};

export default Users;
