import React, { useState, useEffect } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { Box, Divider, Drawer, Typography } from '@mui/material';

import api from '../api';

import { logTableStyle } from '../AuditPage/lib/constants';
import AccountInfo from './AccountDetail/AccountInfo';
import AccountFeatures from './AccountDetail/AccountFeatures';

const columns = [
  { field: '_id', headerName: 'ID', sortable: false, headerClassName: 'column-header', width: 220 },
  { field: 'name', headerName: 'Name', sortable: true, headerClassName: 'column-header', width: 320 },
  { field: 'type', headerName: 'Type', sortable: false, headerClassName: 'column-header', width: 100 },
  { field: 'sso', headerName: 'Enable SSO', sortable: false, headerClassName: 'column-header', width: 100 },
  { field: 'userCount', headerName: 'User', sortable: false, headerClassName: 'column-header', width: 75 },
  { field: 'setCount', headerName: 'Microapp', sortable: false, headerClassName: 'column-header', width: 85 },
  { field: 'libraryCount', headerName: 'Library', sortable: false, headerClassName: 'column-header', width: 80 },
  { field: 'createdAt', headerName: 'Created At', sortable: true, headerClassName: 'column-header', width: 225 },
  { field: 'updatedAt', headerName: 'Last Updated At', sortable: true, headerClassName: 'column-header', width: 225 }
];

const AccountTable = props => {
  const { fetchData, rows, rowCount, paginationModel, setPaginationModel, isLoading, limitSize } = props;

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [premiumFeatures, setPremiumFeatures] = useState([]);

  const onUpdateAccount = async data => {
    const updatedAccount = await api.accounts(selectedAccount._id).patch(data);
    await fetchData();
    setSelectedAccount(updatedAccount);
  };

  const onDisableAccount = async () => {
    const updatedAccount = await api.accounts(selectedAccount._id).toggleDisabled.post();
    await fetchData();
    setSelectedAccount(updatedAccount);
  };

  const getPremiumFeatures = async () => {
    const features = await api.premiumFeatures.getAll();
    setPremiumFeatures(features);
  };

  useEffect(() => {
    getPremiumFeatures();
  }, []);

  return (
    <>
      <DataGrid
        columnHeaderHeight={44}
        rowHeight={44}
        disableColumnMenu
        hideFooterSelectedRowCount
        getRowId={row => row._id}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        pagination
        pageSizeOptions={[limitSize]}
        loading={isLoading}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onRowClick={rows => setSelectedAccount(rows.row)}
        sx={logTableStyle}
      />

      {selectedAccount && (
        <Drawer id="account-details" open anchor="right" width="50vw" onClose={() => setSelectedAccount(null)}>
          <Box width="auto" overflow="scroll" display="flex">
            <AccountFeatures
              account={selectedAccount}
              premiumFeatures={premiumFeatures}
              onUpdateAccount={onUpdateAccount}
            />
            <AccountInfo
              account={selectedAccount}
              onDisableAccount={onDisableAccount}
              onUpdateAccount={onUpdateAccount}
            />
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default AccountTable;
