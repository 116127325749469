import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import permissions from 'mosaic/permissions';
import { AccountType } from 'mosaic/enums';
import { storage } from '../../api';
import { getAccountType } from '../../util';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import InputField from '../../components/Form/InputField';
import SelectField from '../../components/Form/SelectField';
import DateField from '../../components/Form/DateField';

const accountTypes = _.map(AccountType, type => {
  return {
    label: getAccountType({ type }),
    value: type
  };
});

const EditAccount = props => {
  const { open, onClose, account, onUpdateAccount: update } = props;
  const { _id: accountId, name, signupData } = account;
  const user = storage.local.get('user');
  const disabled = !permissions.canAdminUpdateAccount(user) || !!account.disabledAt;

  const onUpdateAccount = (value, key, setObject) => {
    const oldValue = account[key];
    if (value === oldValue) return;

    const data = setObject ? value : { [key]: value };
    update(data);
  };

  const isTrialAccount = account.type === AccountType.SELF_SERVE_TRIAL;
  const trialLimitDate = (signupData && signupData.trialLimitDate) || '';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-account"
      aria-describedby="edit-account-details"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="edit-account">Edit Account: {name} </DialogTitle>
      <DialogContent dividers>
        <InputField
          label="Name"
          value={name}
          id={accountId}
          disabled={disabled}
          onBlur={e => onUpdateAccount(e.target.value, 'name')}
        />
        <SelectField
          id={accountId}
          label="Type"
          options={accountTypes}
          value={account.type}
          disabled={disabled}
          onSelect={option => onUpdateAccount(option.value, 'type')}
        />

        {isTrialAccount && (
          <DateField
            id={accountId}
            label="Trial Date"
            value={moment(trialLimitDate).format('YYYY-MM-DD')}
            disabled={disabled}
            onChange={e => {
              const trialLimitDate = moment(e.target.value).toDate();
              onUpdateAccount({ trialLimitDate }, 'signupData');
            }}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAccount;
