import React, { useState } from 'react';
import {
  IconButtonContainer,
  TooltipContainer,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  ButtonContainer
} from 'tiled-ui';
import { FiPlus } from 'react-icons/fi';
import _ from 'lodash';

import api from '../../api';
import AddConfigKey from './AddConfigKey';
import AddConfigValue from './AddConfigValueRow';
import AddConfigPreview from './AddConfigPreview';

const AddConfigSettingDrawer = ({ showSnack, callback }) => {
  const { isOpen, onOpen, onClose: onCloseDrawer } = useDisclosure();

  const [name, setName] = useState('');
  const [values, setValues] = useState({});

  const onSetValue = (key, value) => {
    const newValue = _.assign({}, values, { [key]: value });
    setValues(newValue);
  };

  const onRemoveValue = key => {
    const newValue = _.unset(values, key);
    setValues(newValue);
  };

  const onCreate = async () => {
    try {
      await api.post(`/config-sets`, { name, values });
      showSnack(`Created config settings "${name}"`);
      onClose();
      await callback();
    } catch (err) {
      showSnack(`Error creating config settings: ${err.message}`);
      onClose();
    }
  };

  const onClose = () => {
    setName('');
    setValues({});
    onCloseDrawer();
  };

  return (
    <>
      <TooltipContainer label="Add new config settings" variant="dark" placement="left">
        <IconButtonContainer
          iconPath={FiPlus}
          borderRadius="50%"
          stokewidth="2px"
          alignSelf="flex-end"
          mb="4"
          mr="2"
          onClick={onOpen}
        />
      </TooltipContainer>

      <Drawer isLazy isOpen={isOpen} onClose={onClose} placement="right" size="md">
        <DrawerContent>
          <DrawerHeader>Add Config Settings</DrawerHeader>

          <DrawerBody>
            <AddConfigKey name={name} setName={setName} />
            <AddConfigValue values={values} onSetValue={onSetValue} onRemoveValue={onRemoveValue} />
            <AddConfigPreview name={name} values={values} />
          </DrawerBody>

          <DrawerFooter>
            <ButtonContainer variant="secondary" onClick={onClose}>
              Cancel
            </ButtonContainer>
            <ButtonContainer ml="2" isDisabled={!name || _.isEmpty(values)} onClick={onCreate}>
              Save
            </ButtonContainer>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddConfigSettingDrawer;
