import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Container from '@mui/material/Container';
import Papa from 'papaparse';
import Promise from 'bluebird';
import { Redirect } from 'react-router-dom';

import './App.css';
import api, { storage } from './api';
import { AccountType } from 'mosaic/enums';

import InfoIcon from '@mui/icons-material/Info';
import { ButtonContainer } from 'tiled-ui';

const ENTERPRISE_FEATURES = [
  'engagementScoring',
  'textTile',
  'scrollingCanvas',
  'quizTile',
  'dynamicInstances',
  'screenSharing',
  'apiAccess',
  'localscriptTile',
  'personalization',
  'multiLibrary'
];
const CREATIVE_FEATURES = ['scrollingCanvas', 'localscriptTile'];
const PRESELECTED_ENTERPRISE = [];
const PRESELECTED_CREATIVE = ['What is a microapp?', 'Investo Pitch Deck' ,'Sales Hub'];

class Tools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountToCreate: AccountType.CREATIVE,
      allKeyedAccounts: {},
      dataToImport: null,
      docSets: [],
      docSetIdsToCopy: [],
      docSetIdsToPublish: [],
      preselectedDocSetIdsToCopy: [],
      preselectedDocSetIdsToPublish: [],
      docSetIdsChecked: [],
      premiumFeatures: [],
      selectedPremiumFeatureIds: [],
      preselectedDocSetIdsStartHere: [],
      docSetIdsStartHere: [],
      importing: false,
      accountsImported: 0,
      errors: null,
      rowsProcessed: 0,
      ready: false,
      user: null
    };
  }

  componentDidMount = async () => {
    const { accountToCreate } = this.state;

    const user = await storage.local.get('user');
    try {
      const allAccounts = await api.accounts.getAll();
      const allKeyedAccounts = _.keyBy(allAccounts, 'name');
      await this.setState({ allKeyedAccounts, user, ready: true });
    } catch {
      window.location = '/';
    }

    try {
      if (user && user.accountId) {
        const docSets = await api.accounts(user.accountId).sets.getAll();
        const sortedDocSets = docSets.sort((dsA, dsB) => {
          if (dsA.name < dsB.name) {
            return -1;
          }
          if (dsB.name < dsA.name) {
            return 1;
          }
          return 0;
        });

        let preselectedDocSetNamesToCopy = [];
        let preselectedDocSetNamesToPublish = [];
        // find microapps that we know will need to be copied and default them
        // here
        if (process.env.NODE_ENV === 'production') {
          preselectedDocSetNamesToCopy =
            accountToCreate === AccountType.CREATIVE ? PRESELECTED_CREATIVE : PRESELECTED_ENTERPRISE;
          preselectedDocSetNamesToPublish =
            accountToCreate === AccountType.CREATIVE ? PRESELECTED_CREATIVE : PRESELECTED_ENTERPRISE;
        } else {
          preselectedDocSetNamesToCopy = ['Font 2', 'Scrolling Canvas'];
          preselectedDocSetNamesToPublish =
            accountToCreate === AccountType.CREATIVE ? ['Scrolling Canvas'] : ['Font 2'];
        }

        const preselectedDocSetIdsToCopy = docSets
          .filter(ds => _.includes(preselectedDocSetNamesToCopy, ds.name))
          .map(ds => ds._id);
        const preselectedDocSetIdsToPublish = docSets
          .filter(ds => _.includes(preselectedDocSetNamesToPublish, ds.name))
          .map(ds => ds._id);

        await this.setState({
          docSets: sortedDocSets,
          preselectedDocSetIdsToCopy,
          preselectedDocSetIdsToPublish
        });

        const premiumFeatures = await api.premiumFeatures.getAll();
        const selectedPremiumFeatureIds = premiumFeatures
          .filter(pf => _.includes(CREATIVE_FEATURES, pf.key))
          .map(pf => pf._id);

        this.setState({ premiumFeatures, selectedPremiumFeatureIds });
      } else {
        window.location = '/';
      }
    } catch (err) {
      storage.local.set('user', null);
      this.setState({ user: null });
    }
  };

  onChangeHandler = e => {
    Papa.parse(e.target.files[0], {
      header: true,
      complete: results => {
        console.log(results.data);
        if (results.data.length === 0) {
          this.setState({
            dataToImport: [],
            errors: ['0 rows in CSV. Please select another file with some data in it.']
          });
        } else {
          const firstResult = results.data[0];
          const requiredKeys = ['Name', 'Email', 'Company name'];
          const missingKeys = _.difference(requiredKeys, _.keys(firstResult));
          if (missingKeys.length !== 0) {
            this.setState({
              dataToImport: [],
              errors: [`The CSV is missing some columns: ${missingKeys}`]
            });
          } else {
            this.setState({
              dataToImport: results.data,
              accountsImported: 0,
              errors: null,
              rowsProcessed: 0,
              docSetIdsToCopy: this.state.preselectedDocSetIdsToCopy.slice(),
              docSetIdsToPublish: this.state.preselectedDocSetIdsToPublish.slice(),
              docSetIdsStartHere: this.state.preselectedDocSetIdsStartHere.slice()
            });
          }
        }
      }
    });
  };

  onChangeType = async e => {
    const { docSets, premiumFeatures } = this.state;
    const accountToCreate = e.target.value;
    const selectedPremiumFeatureIds = premiumFeatures
      .filter(pf =>
        _.includes(accountToCreate === AccountType.CREATIVE ? CREATIVE_FEATURES : ENTERPRISE_FEATURES, pf.key)
      )
      .map(pf => pf._id);

    let docSetNamesToCopy = [];
    let docSetNamesToPublish = [];

    // determine microapps to copy/publish depending on environment
    if (process.env.NODE_ENV === 'production') {
      docSetNamesToCopy = accountToCreate === AccountType.CREATIVE ? PRESELECTED_CREATIVE : PRESELECTED_ENTERPRISE;
      docSetNamesToPublish = accountToCreate === AccountType.CREATIVE ? PRESELECTED_CREATIVE : PRESELECTED_ENTERPRISE;
    } else {
      docSetNamesToCopy = ['Font 2', 'Scrolling Canvas'];
      docSetNamesToPublish = accountToCreate === AccountType.CREATIVE ? ['Scrolling Canvas'] : ['Font 2'];
    }

    const docSetIdsToCopy = docSets.filter(ds => _.includes(docSetNamesToCopy, ds.name)).map(ds => ds._id);
    const docSetIdsToPublish = docSets.filter(ds => _.includes(docSetNamesToPublish, ds.name)).map(ds => ds._id);

    await this.setState({
      selectedPremiumFeatureIds,
      accountToCreate,
      docSetIdsToCopy,
      docSetIdsToPublish
    });
  };

  onDocSetSelect = e => {
    this.setState({ docSetIdsToCopy: e.target.value });
  };

  onFeatureSelect = e => {
    this.setState({ selectedPremiumFeatureIds: e.target.value });
  };

  onDocSetStartHereSelect = e => {
    this.setState({ docSetIdsStartHere: e.target.value });
  };

  importData = async () => {
    const {
      accountToCreate,
      allKeyedAccounts,
      dataToImport,
      docSets,
      docSetIdsToCopy,
      docSetIdsToPublish,
      selectedPremiumFeatureIds
    } = this.state;
    const role = 'Admin';
    await this.setState({ importing: true });
    const ops = Promise.mapSeries(dataToImport, async data => {
      const name = data['Company name'] !== '' ? data['Company name'] : data.Name;
      const contact = {
        name: data.Name,
        email: data.Email
      };
      if (_.get(data, 'Phone number', '') >= 10) {
        contact.phone = data['Phone number'];
      }
      // upload the account
      let account = null;
      try {
        account = await api.accounts.post({
          name,
          contact,
          type: accountToCreate,
          features: selectedPremiumFeatureIds // enable features
        });
      } catch (err) {
        const errMsg = `${contact.email} (${contact.name}): ${err.message}. Are you trying to upload a user into an existing account? This isn't allowed.`;
        const errors = (this.state.errors || []).slice(0);
        errors.push(errMsg);
        this.setState({ errors });
      }

      // upload the user
      if (account) {
        let user = null;
        const roles = await api.accounts(account._id).roles.getAll();
        console.log(roles);
        const builderRole = roles.find(a => a.name === role);
        console.log(builderRole);
        const userData = {
          users: {
            name: data.Name,
            email: data.Email,
            accountId: account._id,
            role: builderRole
          },
          options: { sendEmail: true }
        };
        try {
          const usersResponse = await api.users.post(userData);
          user = usersResponse.users[0];
        } catch (err) {
          const errMsg = `${contact.email} (${contact.name}): ${err.message}`;
          const errors = (this.state.errors || []).slice(0);
          errors.push(errMsg);
          this.setState({ errors });
        }

        try {
          // copy microapps over if we can (errors here are not good but are tolerated)
          const dupeOps = Promise.mapSeries(docSetIdsToCopy, async dsId => {
            const docSetToCopy = docSets.find(ds => ds._id === dsId);
            return await api.documentSets(dsId).duplicate.post({
              setId: dsId,
              name: docSetToCopy.name,
              newAccountId: account._id,
              shouldPublish: _.includes(docSetIdsToPublish, dsId),
              targetAccountUserId: user._id
            });
          });
          await dupeOps;
          this.setState({ accountsImported: this.state.accountsImported + 1 });
        } catch (err) {
          const errMsg = `${contact.email} (${contact.name}): ${err.message}`;
          const errors = (this.state.errors || []).slice(0);
          errors.push(errMsg);
          this.setState({ errors });
        }
      } else {
        // we seem to be getting issues with accounts that get created along with users
        // but then the users don't have role. Let's see if this user needs to be repaired.
        console.log('Trying to repair account...');
        try {
          const account = allKeyedAccounts[name];
          if (account) {
            const users = await api.accounts(account._id).users.getAll();
            const roles = await api.accounts(account._id).roles.getAll();
            const builderRole = roles.find(a => a.name === role);
            // find the user
            const user = users.find(user => user.email === data.Email);
            if (user && _.isNil(user.roleId) && builderRole) {
              // see if we can fix this up
              await api.users(user._id).patch({
                role: builderRole
              });

              const errMsg = `${contact.email} (${contact.name}): Fixed role`;
              const errors = (this.state.errors || []).slice(0);
              errors.push(errMsg);
              this.setState({ errors });
            }
          }
        } catch (err) {
          // noop since the main error has already been logged
        }
      }
      this.setState({ rowsProcessed: this.state.rowsProcessed + 1 });

      // const data = {
      //   setId: req.params.setId,
      //   name: req.body.name,
      //   accountId: req.body.newAccountId || null,
      //   replaceId: req.body.replaceId || null
      // };
    });
    await ops;
    await this.setState({ importing: false });
  };

  handleMicroappToggle = value => () => {
    const { docSetIdsChecked } = this.state;
    const exists = docSetIdsChecked.find(id => id === value);
    const newDocSetIdsChecked = exists
      ? _.filter(docSetIdsChecked, id => id !== value)
      : _.union(docSetIdsChecked, [value]);
    this.setState({ docSetIdsChecked: newDocSetIdsChecked });
  };

  handleCheckedRight = () => {
    const { docSetIdsChecked, docSetIdsToCopy, docSets } = this.state;
    const docSetIds = docSets.map(ds => ds._id);
    const leftSideIds = _.difference(docSetIds, docSetIdsToCopy);
    const newDocSetIdsToCopy = _.union(docSetIdsToCopy, _.intersection(docSetIdsChecked, leftSideIds));
    const newDocSetIdsChecked = _.difference(docSetIdsChecked, newDocSetIdsToCopy);
    this.setState({ docSetIdsChecked: newDocSetIdsChecked, docSetIdsToCopy: newDocSetIdsToCopy });
  };

  handleCheckedLeft = () => {
    const { docSetIdsChecked, docSetIdsToCopy } = this.state;
    const rightSideIds = docSetIdsToCopy;
    const rightSideCheckedIds = _.intersection(docSetIdsChecked, rightSideIds);
    const newDocSetIdsToCopy = _.difference(docSetIdsToCopy, rightSideCheckedIds);
    const newDocSetIdsChecked = _.difference(docSetIdsChecked, rightSideCheckedIds);
    this.setState({ docSetIdsChecked: newDocSetIdsChecked, docSetIdsToCopy: newDocSetIdsToCopy });
  };

  handlePublishChange = id => e => {
    const { docSetIdsToPublish } = this.state;
    let newDocSetIdsToPublish = null;

    e.stopPropagation();
    e.preventDefault();
    if (docSetIdsToPublish.indexOf(id) !== -1) {
      newDocSetIdsToPublish = _.difference(docSetIdsToPublish, [id]);
    } else {
      newDocSetIdsToPublish = _.union(docSetIdsToPublish, [id]);
    }

    this.setState({ docSetIdsToPublish: newDocSetIdsToPublish });
  };

  renderCustomMicroappList(items, hasSwitch) {
    const { docSetIdsChecked, docSets, docSetIdsToPublish } = this.state;
    return (
      <Fragment>
        {hasSwitch && (
          <div
            style={{
              position: 'relative',
              left: 110,
              top: 0,
              fontSize: 14
            }}
          >
            Publish?
          </div>
        )}
        <Paper style={{ width: 300, height: 230, overflow: 'auto' }}>
          <List dense component="div" role="list">
            {items.map(value => {
              const labelId = `transfer-list-item-${value}-label`;
              const currDs = docSets.find(ds => ds._id === value);

              return (
                <ListItem key={value} role="listitem" button onClick={this.handleMicroappToggle(value)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={docSetIdsChecked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={currDs.name} />
                  {hasSwitch && (
                    <Switch
                      checked={docSetIdsToPublish.indexOf(value) !== -1}
                      onClick={this.handlePublishChange(value)}
                      value="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  )}
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Paper>
      </Fragment>
    );
  }

  renderMicroappLists() {
    const { docSetIdsToCopy, docSets } = this.state;
    const docSetIds = docSets.map(ds => ds._id);
    const rightSideIds = docSetIdsToCopy;
    const leftSideIds = _.difference(docSetIds, docSetIdsToCopy);

    return (
      <div style={{ padding: '24px 0' }}>
        <div style={{ fontWeight: 'bold' }}>Microapps To Copy</div>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item>{this.renderCustomMicroappList(leftSideIds, false)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                onClick={this.handleCheckedRight}
                disabled={leftSideIds.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={this.handleCheckedLeft}
                disabled={rightSideIds.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{this.renderCustomMicroappList(rightSideIds, true)}</Grid>
        </Grid>
      </div>
    );
  }

  renderStartHereCategory() {
    const { docSets, docSetIdsToCopy, docSetIdsStartHere } = this.state;
    const docSetToCopy = docSets.filter(ds => _.includes(docSetIdsToCopy, ds._id));
    const finalDocSetIdsStartHere = docSetIdsStartHere.filter(dsId => _.includes(docSetIdsToCopy, dsId));
    return (
      <div style={{ padding: '12px 0' }}>
        <div>'Start Here' Category Microapps</div>
        <Select value={finalDocSetIdsStartHere} multiple onChange={this.onDocSetStartHereSelect}>
          {docSetToCopy.map((ds, index) => (
            <MenuItem key={index} value={ds._id}>
              {ds.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderAccountImport = () => {
    const {
      accountToCreate,
      accountsImported,
      dataToImport,
      errors,
      importing,
      premiumFeatures,
      rowsProcessed,
      selectedPremiumFeatureIds
    } = this.state;

    const readyToImportAccounts = !!(dataToImport && dataToImport.length);

    return (
      <Container>
        <div style={{ fontSize: 18, padding: 12 }}>Upload Account CSV</div>
        <input
          accept="text/csv"
          label="Upload"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={this.onChangeHandler}
          onClick={event => {
            // need this to upload the same file twice
            event.target.value = null;
          }}
        />
        <label htmlFor="raised-button-file">
          <Button component="span" variant="contained" color="primary" isDisabled={importing}>Upload CSV</Button>
        </label>
        <div style={{ fontSize: 14, marginTop: 8, fontStyle: 'italic' }}>
          Must contain columns: Name, Email, Company name, Phone number (optional)
        </div>
        {readyToImportAccounts && (
          <Fragment>
            {this.renderMicroappLists()}
            <div style={{ padding: '12px 0' }}>
              <div style={{ fontWeight: 'bold' }}>Select Account Type to Create</div>
              <Select value={accountToCreate} onChange={this.onChangeType}>
                <MenuItem value={AccountType.CREATIVE}>Creative</MenuItem>
                <MenuItem value={AccountType.ENTERPRISE}>Enterprise</MenuItem>
                <MenuItem value={AccountType.SEISMIC}>Seismic</MenuItem>
              </Select>
              <div style={{ fontWeight: 'bold', paddingTop: 20 }}>Premium Features to Enable</div>
              <Select value={selectedPremiumFeatureIds} multiple onChange={this.onFeatureSelect}>
                {premiumFeatures.map((feature, index) => (
                  <MenuItem key={index} value={feature._id}>
                    {feature.title}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Fragment>
        )}
        {readyToImportAccounts && accountsImported === 0 && rowsProcessed === 0 && (
          <div style={{ padding: '12px 0' }}>
            <Button
              component="span"
              variant="contained"
              color="primary"
              onClick={this.importData}
              disabled={importing}
            >
              {`Import ${dataToImport.length} Accounts`}
            </Button>
          </div>
        )}
        {(accountsImported !== 0 || rowsProcessed !== 0) && (
          <Fragment>
            <div style={{ padding: '12px 0' }}>
              <LinearProgress variant="determinate" value={(accountsImported / dataToImport.length) * 100} />
            </div>
            <div style={{ padding: '12px 0' }}>
              <div>{`${rowsProcessed}/${dataToImport.length} Rows Processed`}</div>
              <div>{`${accountsImported} Accounts Imported`}</div>
            </div>
          </Fragment>
        )}
        {dataToImport && rowsProcessed === dataToImport.length && (!errors || errors.length === 0) && (
          <div>Done!</div>
        )}
      </Container>
    )
  }

  renderErrors = () => {
    const { errors } = this.state;

    if (errors && errors.length) {
      return(
        <div style={{ marginTop: 12 }}>
          <div style={{ color: 'red' }}>Errors</div>
          {errors.map((errMsg, index) => (
            <div key={index} style={{ color: 'red', fontSize: 13 }}>
              {errMsg}
            </div>
          ))}
        </div>
      )}

    return null;
  }

  render() {
    const {
      ready,
      user
    } = this.state;

    if (!ready) {
      return null;
    }

    if (!user) {
      return <Redirect to="/" />;
    }

    return (
      <Container maxWidth="md">
        <p className="info-box">
          <InfoIcon className="info-box-icon" />
          WIP, please feel free to provide feedback on this application.
        </p>
        <div className="page-title">Account Tools</div>
        {this.renderAccountImport()}
        {this.renderErrors()}
      </Container>
    );
  }
}

export default Tools;
