import { AccountType } from 'mosaic/enums';

const AccountTypes = {
  [AccountType.ENTERPRISE]: 'Enterprise',
  [AccountType.DESIGNER]: 'Designer',
  [AccountType.CREATIVE]: 'Creative',
  [AccountType.SELF_SERVE_TRIAL]: 'Trial',
  [AccountType.CHAMPION]: 'Champion',
  [AccountType.TEAM]: 'Team',
  [AccountType.ORGANIZATION]: 'Organization',
  [AccountType.SEISMIC]: 'Seismic'
};

export const sortByKey = (arr, key, dir = 'asc') => {
  return arr.sort((a, b) => {
    if (!a[key] || !b[key]) return 0;

    const aValue = a[key].toLowerCase ? a[key].toLowerCase() : a[key];
    const bValue = b[key].toLowerCase ? b[key].toLowerCase() : b[key];

    if (aValue < bValue) {
      return dir === 'asc' ? -1 : 1;
    }

    if (aValue > bValue) {
      return dir === 'asc' ? 1 : -1;
    }

    return 0;
  });
};

export const getAccountType = accountParam => {
  const t = typeof accountParam === 'string' ? accountParam : accountParam?.type;

  return AccountTypes[t];
};
