import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const DeleteTagDialog = ({ tag, onClose, onDelete }) => {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Delete tag</DialogTitle>
      <DialogContent dividers>
        Are you sure you want to delete this tag, <strong>"{tag.name}"</strong>?
        <br />
        It will be deleted from all the accounts and this tag will be removed from all microapps.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTagDialog;
