import React, { useRef } from 'react';
import { SelectContainer, TextContainer, Box, HStack } from 'tiled-ui';
import PermissionsPopover from './PermissionsPopover';

const UserLibraries = props => {
  const { microappLibraries, groups, roles, isDisabled } = props;
  const ref = useRef();

  return !microappLibraries.length ? null : (
    <>
      <TextContainer textStyle="textXS" fontWeight="medium">
        Microapp Libraries:
      </TextContainer>
      <Box id="user-libraries" mb="4">
        {microappLibraries.map(l => {
          const { _id: libraryId, name } = l.libraryId;
          const libraryGroups = groups.filter(g => g.libraryId === libraryId);

          const selectedRole = roles.find(r => r.value === l.roleId);
          const selectedGroup = libraryGroups.find(g => g.value === l.groupPath);

          return (
            <HStack id={libraryId} key={libraryId} ml="6" my="2">
              <TextContainer
                textStyle="textXS"
                fontWeight="medium"
                w="25%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {name}
              </TextContainer>
              <SelectContainer minW="25%" isLazy options={roles} isDisabled selected={selectedRole} />
              {!!groups.length && (
                <SelectContainer minW="25%" isLazy isDisabled options={libraryGroups} selected={selectedGroup} />
              )}

              <PermissionsPopover
                ref={ref}
                triggerLabel="View library permissions"
                permissions={l.permissions}
                groupPermissions={l.groupPermissions}
              />
            </HStack>
          );
        })}
      </Box>
    </>
  );
};

export default UserLibraries;
