import React from 'react';
import { TextContainer, InputContainer, VStack } from 'tiled-ui';

const AddConfigKey = ({ name, setName }) => {
  return (
    <VStack spacing="1" alignItems="flex-start" mb="5">
      <TextContainer textStyle="textSm" fontWeight="bold">
        Name{' '}
        <span style={{ color: 'gray', fontSize: '10px', fontWeight: 'medium' }}>
          (use kebab case ex. engagement-scoring)
        </span>
      </TextContainer>
      <InputContainer w="95%" value={name} onChange={e => setName(e.target.value)} />
    </VStack>
  );
};

export default AddConfigKey;
