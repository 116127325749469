import React from 'react';
import _ from 'lodash';
import { baseUrl } from '../api';
import { Box, Button } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import SearchField from '../components/Form/SearchField';
import SelectField from '../components/Form/SelectField';
import Checkbox from '../components/Form/CheckBox';

const METHOD_OPTIONS = [
  { label: 'GET', value: 'GET' },
  { label: 'POST', value: 'POST' },
  { label: 'PATCH', value: 'PATCH' },
  { label: 'DELETE', value: 'DELETE' }
];

const Spacing = () => {
  return <Box marginRight="20px" />;
};

const LogTableFilter = props => {
  const { onSetFilter, filter } = props;

  const setUserQuery = e => {
    const { value } = e.target;
    onSetFilter('userEmail', value.toLowerCase());
  };

  const setAccountQuery = e => {
    const { value } = e.target;

    if (value.match(/^[0-9a-fA-F]{24}$/)) onSetFilter('accountId', value);
    else onSetFilter('accountName', value);
  };

  const setMethodQuery = option => {
    const newValue = option.value;
    if (!newValue) return onSetFilter('method', null);

    const previousFilter = filter.method || [];
    let newFilter;
    if (previousFilter.includes(newValue)) {
      newFilter = previousFilter.filter(f => f !== newValue);
    } else {
      if (previousFilter.length === 3) return onSetFilter('method', null);
      newFilter = [...previousFilter, newValue];
    }

    onSetFilter('method', newFilter);
  };

  const onExportClick = () => {
    const query = new URLSearchParams(filter).toString();
    const url = `${baseUrl}/audit/export`;
    window.open(`${url}?${query}`);
  };

  return (
    <Box display="flex" flexDirection="row" paddingTop="30px" alignItems="center">
      <SearchField
        label="Search events done by actor email"
        onChange={_.debounce(setUserQuery.bind(this), 750)}
        sx={{ width: '300px' }}
      />
      <Spacing />

      <SearchField
        label="Search events done on account by id or name"
        onChange={_.debounce(setAccountQuery.bind(this), 750)}
        sx={{ width: '300px' }}
      />
      <Spacing />

      <SelectField
        multiple
        label="Method"
        sx={{ width: '225px', m: 0 }}
        value={filter.method || []}
        options={METHOD_OPTIONS}
        disabled={false}
        onSelect={setMethodQuery}
      />
      <Spacing />

      <Checkbox label="Superuser" onChange={e => onSetFilter('superuser', e.target.checked)} />
      <Checkbox label="Assumed Role" onChange={e => onSetFilter('assumedRole', e.target.checked)} />

      <Button
        sx={{ color: 'gray', ml: 'auto' }}
        variant="text"
        size="small"
        endIcon={<FileDownload />}
        onClick={onExportClick}
      >
        Export CSV
      </Button>
    </Box>
  );
};

export default LogTableFilter;
