export const USER = 'user';
export const GROUP = 'group';
export const ADMIN = 'admin';

const MICROAPP_PERMISSIONS = [
  // builder?
  { key: 'HUB_ACCESS', label: 'Hub Access', type: [USER] },
  // microapp
  { key: 'C_CAMPAIGN', label: 'Create Microapp', type: [ADMIN] },
  { key: 'CD_CAMPAIGN', label: 'Create/Delete Microapp', type: [USER, GROUP] },
  { key: 'UPDATE_CAMPAIGN', label: 'Update Microapp', type: [USER, GROUP] },
  { key: 'PUBLISH_CAMPAIGN', label: 'Publish Microapp', type: [USER, GROUP] },
  { key: 'PUBLISH_TEMPLATE', label: 'Publish Microapp Template', type: [USER, GROUP] },
  // instances 1.0
  { key: 'C_INSTANCE', label: 'Create Instance', type: [ADMIN] },
  { key: 'CD_INSTANCE', label: 'Create/Delete Instance', type: [USER, GROUP] },
  { key: 'UPDATE_INSTANCE', label: 'Update Instance', type: [USER, GROUP] },
  { key: 'MANAGE_INSTANCES', label: 'Manage Instances', type: [USER, GROUP] }
];

const ANALYTICS_PERMISSIONS = [
  { key: 'ANALYTICS', label: 'Access Analytics', type: [USER, GROUP] },
  { key: 'CUSTOMER_ANALYTICS', label: 'Access Customer Analytics', type: [USER, GROUP] }
];

const ORG_PERMISSIONS = [
  { key: 'CD_USER', label: 'Create/Delete User', type: [USER, GROUP, ADMIN] },
  { key: 'UPDATE_USER', label: 'Update User', type: [USER, GROUP, ADMIN] },
  { key: 'CD_GROUP', label: 'Create/Delete Group', type: [USER, GROUP, ADMIN] },
  { key: 'UPDATE_GROUP', label: 'Update Group', type: [USER, GROUP, ADMIN] },
  { key: 'UPDATE_ACCOUNT', label: 'Update Account', type: [USER, ADMIN] },
  { key: 'READ_ORG', label: 'Read Org', type: [USER, GROUP, ADMIN] },
  { key: 'ASSIGN', label: 'Assign', type: [USER] },
  { key: 'BILLING', label: 'Subscribe', type: [USER] },
  { key: 'SUBSCRIBE', label: 'Billing', type: [USER] }
];

const TILED_ADMIN_PERMISSIONS = [
  { key: 'CD_ACCOUNT', label: 'Create/Delete Account', type: [ADMIN] },
  { key: 'HIJACK', label: 'Hijack', type: [ADMIN] },
  { key: 'HIJACK_CREATIVE_ACCOUNT', label: 'Hijack Creative Account', type: [ADMIN] },
  { key: 'HIJACK_DESIGNER_ACCOUNT', label: 'Hijack Designer Account', type: [ADMIN] }
];

export const PERMISSIONS = {
  microapp: MICROAPP_PERMISSIONS,
  organization: ORG_PERMISSIONS,
  analytics: ANALYTICS_PERMISSIONS,
  admin: TILED_ADMIN_PERMISSIONS
};

export const TOOLTIP = {
  def: {
    ACCOUNT_ADMIN: 'This means user has full permissions i.e. multi-library definition of Account Admin',
    JOIN_SLUG: 'Used as unique identifer for broadcast room. Must be 28+ characters',
    DEFAULT_MESSAGE: 'Used as email body message when sharing via Public API'
  }
};
