import React, { useState, useEffect } from 'react';
import { HStack, VStack, TextContainer, InputContainer, IconButtonContainer, Checkbox, Flex } from 'tiled-ui';
import { FiPlus, FiX } from 'react-icons/fi';
import _ from 'lodash';
import { isObjectType } from './util';

const AddConfigValueRow = props => {
  const { onSetValue, onRemoveValue, k = '', v = '' } = props;

  const [name, setName] = useState(k);
  const [value, setValue] = useState(v);
  const [isObject, setIsObject] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (k) setName(k);
    if (v) {
      const isValueObject = isObjectType(v);
      const newValue = isValueObject ? JSON.stringify(v) : v;

      setValue(newValue);
      if (isValueObject) setIsObject(isValueObject);
    }
  }, []);

  const onChange = e => {
    if (error) setError(null);
    setValue(e.target.value);
  };

  const onBlur = async () => {
    try {
      if (!value) return;
      const newValue = isObject ? await JSON.parse(value) : value;
      onSetValue(name, newValue);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <HStack spacing="4" ml="4" my="2" alignItems="flex-start">
      <VStack spacing="1" alignItems="flex-start">
        <TextContainer textStyle="textSm" fontWeight="medium">
          key
        </TextContainer>
        <InputContainer autoFocus isDisabled={!!k} value={name} w="95%" onChange={e => setName(e.target.value)} />
      </VStack>

      <VStack spacing="1" alignItems="flex-start">
        <HStack w="100%" justifyContent="space-between">
          <TextContainer textStyle="textSm" fontWeight="medium">
            value
          </TextContainer>
          <Checkbox size="sm" onChange={() => setIsObject(!isObject)}>
            <TextContainer textStyle="textXXS">object?</TextContainer>
          </Checkbox>
        </HStack>

        <InputContainer
          isInvalid={!!error}
          isDisabled={!name}
          w="95%"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {!!error && (
          <TextContainer textStyle="textXXS" color="red.40" fontWeight="medium">
            Must be JSON format
          </TextContainer>
        )}
      </VStack>

      {!!k && (
        <Flex alignSelf="center">
          <IconButtonContainer
            mt="6"
            size="xs"
            variant="secondaryIcon"
            iconPath={FiX}
            onClick={() => onRemoveValue(v)}
          />
        </Flex>
      )}
    </HStack>
  );
};

const AddConfigValue = ({ values, onSetValue, onRemoveValue }) => {
  const [newValue, setNewValue] = useState(false);

  const onSet = (key, value) => {
    onSetValue(key, value);
    setNewValue(false);
  };

  return (
    <VStack spacing="4" alignItems="flex-start" mb="5">
      <HStack>
        <TextContainer textStyle="textSm" fontWeight="bold">
          Values
        </TextContainer>
        <IconButtonContainer
          ml="2"
          iconPath={FiPlus}
          variant="secondary"
          size="xs"
          isDisabled={!!newValue}
          onClick={() => setNewValue(true)}
        />
      </HStack>

      {_.map(values, (v, k) => (
        <AddConfigValueRow onSetValue={onSetValue} v={v} k={k} key={k} onRemoveValue={onRemoveValue} />
      ))}

      {newValue && <AddConfigValueRow onSetValue={onSet} key="new-value" />}
    </VStack>
  );
};

export default AddConfigValue;
