import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import api from '../api';
import { getAccountType } from '../util';
import AccountTable from './AccountTable';
import AccountTableFilter from './AccountTableFilter';

const PAGE_SIZE = 20;

const formatAccountData = data => {
  return data.map(d => {
    return {
      ...d,
      name: d.disabledAt ? `(Disabled) ${d.name} ` : d.name,
      isDisabled: !!d.disabledAt,
      rawType: d.type,
      type: getAccountType(d.type),
      sso: d.enableSSO ? 'true' : null,
      libraryCount: d.libraries.length,
      createdAt: d.createdAt ? new Date(d.createdAt).toISOString() : null,
      updatedAt: d.createdAt ? new Date(d.updatedAt).toISOString() : null
    };
  });
};

const AllAccounts = ({ showSnack }) => {
  const [filter, setDataFilter] = useState({});
  const [data, setData] = useState({});

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    const data = await api.accounts.getAll({ ...filter, page: currentPage + 1, limit: PAGE_SIZE });

    const accounts = formatAccountData(data.accounts);
    setData({ ...data, accounts });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage, filter]);

  const onSetFilter = (key, value) => {
    const filterObj = _.cloneDeep(filter);
    if (!Array.isArray(value) && !value) delete filterObj[key];
    else filterObj[key] = value;

    if (key === 'name') delete filterObj._id;

    setDataFilter(filterObj);
  };

  return (
    <Container maxWidth="xl" disableGutters sx={{ maxHeight: '100vh', overflow: 'hidden' }}>
      <Box padding="20px" position="sticky" top={0} bgcolor="white" zIndex="appBar">
        <Typography textAlign="left" variant="h5" fontWeight="bold">
          Accounts
        </Typography>
        <AccountTableFilter filter={filter} onSetFilter={onSetFilter} />
      </Box>
      <AccountTable
        rows={data.accounts || []}
        rowCount={data.totalDocs || 0}
        paginationModel={{ page: currentPage, pageSize: PAGE_SIZE }}
        setPaginationModel={pagination => setCurrentPage(pagination.page)}
        isLoading={isLoading}
        limitSize={PAGE_SIZE}
        fetchData={fetchData}
      />
    </Container>
  );
};

export default AllAccounts;
