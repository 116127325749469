import React, { useState } from 'react';
import { Box, HStack, VStack, TextContainer, Checkbox, GridItem, InputContainer } from 'tiled-ui';
import _ from 'lodash';

import { UserPermissions } from 'mosaic/enums';
import { PERMISSIONS, ADMIN } from '../../util/constants';

const Permissions = props => {
  const { label, type, isDisabled = true, value, reduceScale } = props;

  const [searchValue, setSearchValue] = useState(value || 0);

  const onSubmit = e => setSearchValue(e.target.value);

  const onChange = (key, e) => {
    const { checked } = e.target;

    const permValue = UserPermissions[type.toUpperCase()][key];
    const newValue = checked ? searchValue + permValue : searchValue - permValue;

    setSearchValue(newValue);
  };

  const hasPermissions = key => searchValue & UserPermissions[type.toUpperCase()][key];

  const fontSize = reduceScale ? '0.75vw' : '12px';
  const fontWeight = reduceScale ? 'bold' : '';

  return (
    <Box mb="7">
      <TextContainer textStyle="textSm" fontSize={fontSize} fontWeight="bold">
        {label}
      </TextContainer>

      <InputContainer
        w="25%"
        size="xs"
        placeholder="Enter value"
        my="2"
        value={searchValue}
        onBlur={onSubmit}
        isDisabled={isDisabled}
        _disabled={{ color: 'black' }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit(e);
          }
        }}
      />

      <HStack alignItems="flex-start" flex="4" fontSize={fontSize} fontWeight={fontWeight}>
        {_.map(PERMISSIONS, (permission, key) => {
          const shouldSkipAdminList = type !== ADMIN && key === ADMIN;
          const shouldSkipAnalyticsList = type === ADMIN && key === 'analytics';
          if (shouldSkipAdminList || shouldSkipAnalyticsList) return null;

          return (
            <VStack key={`${key}-${permission}`} alignItems="flex-start" flex="1">
              <TextContainer textStyle="textXS" color="gray.60" fontSize={fontSize} fontWeight={fontWeight}>
                {_.startCase(key)}
              </TextContainer>

              {permission.map(p => {
                if (!p.type.includes(type)) return null;

                return (
                  <GridItem fontSize={fontSize} fontWeight={fontWeight}>
                    <Checkbox
                      isDisabled={isDisabled}
                      isChecked={hasPermissions(p.key)}
                      size="sm"
                      fontSize={fontSize}
                      fontWeight={fontWeight}
                      onChange={onChange.bind(this, p.key)}
                    >
                      <TextContainer textStyle="textXS" fontSize={fontSize} fontWeight={fontWeight}>
                        {p.label}
                      </TextContainer>
                    </Checkbox>
                  </GridItem>
                );
              })}
            </VStack>
          );
        })}
      </HStack>
    </Box>
  );
};

export default Permissions;
