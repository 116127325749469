import React from 'react';
import { Box } from 'tiled-ui';

import PermissionTier from '../components/Permissions/PermissionTier';

const RolesSandbox = () => {
  return (
    <Box textAlign="left" maxW="650px">
      <PermissionTier key="user" label="Permissions" type="user" isDisabled={false} />
      <PermissionTier key="group" label="Group Permissions" type="group" isDisabled={false} />
      <PermissionTier key="admin" label="Admin Permissions" type="admin" isDisabled={false} />
    </Box>
  );
};

export default RolesSandbox;
