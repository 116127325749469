import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Spinner,
  TextContainer,
  VStack
} from 'tiled-ui';
import _ from 'lodash';

import KeyValue from './KeyValue';
import AddConfigSettingDrawer from './AddConfigSettingDrawer';

import api from '../../api';

const ConfigSettings = ({ showSnack }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [settings, setSettings] = useState([]);

  const getData = async () => {
    setIsFetching(true);
    const configSets = await api.fetch('config-sets');

    setSettings(configSets);
    setIsFetching(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isFetching) return <Spinner />;

  return (
    <VStack alignItems="stretch">
      <AddConfigSettingDrawer showSnack={showSnack} callback={getData} />

      {settings.map(setting => {
        return (
          <Accordion key={setting._id} allowToggle allowMultiple>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    justifyContent="space-between"
                    cursor="pointer"
                    border="none"
                    outline="none"
                    borderRadius="round"
                    bg="gray.10"
                    _hover={{ bg: 'gray.20' }}
                    _expanded={{ bg: 'blue.20' }}
                  >
                    <TextContainer textStyle="textSm" fontWeight="bold">
                      {setting.name}
                    </TextContainer>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel aria-expanded={isExpanded} _expanded={{ bg: 'blue.10' }} mb="2" p="5">
                    <VStack pl="2" alignItems="flex-start">
                      {_.map(setting.values, (value, key) => (
                        <KeyValue value={value} key={key} dataKey={key} />
                      ))}
                    </VStack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        );
      })}
    </VStack>
  );
};

export default ConfigSettings;
