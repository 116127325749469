import React, { useState } from 'react';
import { AddCircleOutlined } from '@mui/icons-material';

import InputField from '../components/Form/InputField';
import './TagList.css';
import { Tooltip } from '@mui/material';

const CreateTag = ({ createTag }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="create-tag-input">
      <Tooltip title="Create tag" placement="top-end">
        <AddCircleOutlined onClick={() => setIsEditing(!isEditing)} />
      </Tooltip>

      {isEditing && (
        <InputField
          id="tag"
          variant="outlined"
          label="Create new tag"
          disabled={false}
          onBlur={createTag}
          clearAfterBlur
          margin="none"
          width="50%"
        />
      )}
    </div>
  );
};

export default CreateTag;
