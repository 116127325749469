import React from 'react';
import mosaicPermissions from 'mosaic/permissions';

import { storage } from '../../api';

import PermissionTier from './PermissionTier';

const UserPermissions = props => {
  const currentUser = storage.local.get('user');
  const {
    permissions,
    groupPermissions,
    adminPermissions,
    reduceScale = false,
    isDisabled = true,
    showAdmin = false,
    showOnlyAdmin = false,
    ...rest
  } = props;

  const shouldDisable = !(mosaicPermissions.isSuperUser(currentUser) && !isDisabled);

  return (
    <>
      {!showOnlyAdmin && (
        <>
          <PermissionTier
            key="user"
            label="Permissions"
            type="user"
            value={permissions}
            reduceScale={reduceScale}
            {...rest}
          />
          <PermissionTier
            key="group"
            label="Group Permissions"
            type="group"
            value={groupPermissions}
            reduceScale={reduceScale}
            {...rest}
          />
        </>
      )}

      {(showAdmin || showOnlyAdmin) && (
        <PermissionTier
          key="admin"
          label="Admin Permissions"
          type="admin"
          reduceScale={reduceScale}
          value={adminPermissions}
          isDisabled={shouldDisable}
          {...rest}
        />
      )}
    </>
  );
};

export default UserPermissions;
