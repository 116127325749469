import _ from 'lodash';
import React, { Component } from 'react';
import { Button, TextField, Container, Grid } from '@mui/material';
import { Skeleton } from '@mui/lab';
import { Redirect } from 'react-router-dom';

import api, { storage } from './api';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      errors: null,
      ready: false,
      user: null,
      formData: {},
      settings: {}
    };
  }

  componentDidMount = async () => {
    const user = await storage.local.get('user');
    try {
      await this.setState({ user, ready: true });
    } catch {
      window.location = '/';
    }

    try {
      if (user && user.accountId) {
        api.configSets.hubSettings
          .getAll()
          .then(settings => this.setState({ settings: settings.values }))
          .catch(_.noop);
      } else {
        window.location = '/';
      }
    } catch (err) {
      storage.local.set('user', null);
      this.setState({ user: null });
    }
  };

  onFormChange = async e => {
    const { formData } = this.state;

    _.set(formData, e.target.name, e.target.value || '');
    this.setState({ formData });
  };

  onFormSubmit = async e => {
    const { formData } = this.state;
    const { showSnack } = this.props;

    e.preventDefault();

    // comment out for local shared link testing, otherwise just allow shared links
    if (!/^(https?):\/\/(swaintastic\.|share-test\.|s\.|s-pp\.|s-hotfix\.)(tiled\.co)[^\s$.?#].[^\s]*$/.test(formData.loginMicroappUrl)) {
      showSnack('Not an approved URL, must be a Tiled shared link.');
      return;
    }

    if (formData.loginMicroappUrl) {
      const url = new URL(formData.loginMicroappUrl);
      url.searchParams.set('hideLoader', 'true');
      formData.loginMicroappUrl = url;
    }

    if (formData.selfserveMicroappUrl) {
      const selfserveUrl = new URL(formData.selfserveMicroappUrl);
      selfserveUrl.searchParams.set('hideLoader', 'true');
      formData.selfserveMicroappUrl = selfserveUrl;
    }

    this.setState({ saving: true }, async () => {
      try {
        const settings = await api.configSets.hubSettings.post({ values: formData });
        this.setState({ settings, formData: {}, saving: false });
        showSnack('Url saved');
      } catch (err) {
        this.setState({ saving: false });
        showSnack(`${err.message}`);
      }
    });
  };

  removeEmbededUrl = async e => {
    const { showSnack } = this.props;

    this.setState({ saving: true }, async () => {
      try {
        const settings = await api.configSets.hubSettings.post({ values: { loginMicroappUrl: null } });
        this.setState({ settings, formData: {}, saving: false });
        showSnack('Url removed');
      } catch (err) {
        this.setState({ saving: false });
        showSnack(`${err.message}`);
      }
    });
  };

  render() {
    const { formData, saving, ready, user, settings } = this.state;
    if (!ready) {
      return (
        <div style={{ width: 800, padding: 24 }}>
          <Skeleton variant="rect" width={'100%'} height={300} />
        </div>
      );
    }

    if (!user) {
      return <Redirect to="/" />;
    }

    if (!settings) {
      return null;
    }
    return (
      <Container maxWidth="md">
        <Container>
          <div className="page-title">Hub Settings</div>
          <form>
            <TextField
              type="text"
              onChange={this.onFormChange}
              name="loginMicroappUrl"
              value={formData.loginMicroappUrl || ''}
              autoFocus
              fullWidth
              label="Embedded login microapp url"
              helperText="Appears on the login screen of the Hub. When not set, a default image shows up instead."
            />
            <br />
            <TextField
              type="text"
              onChange={this.onFormChange}
              name="selfserveMicroappUrl"
              value={formData.selfserveMicroappUrl || ''}
              fullWidth
              label="Embedded self serve microapp url"
              helperText="Appears on the self serve screen of the Hub."
            />
            <br />
            <Button
              onClick={this.onFormSubmit}
              type="submit"
              component="span"
              variant="contained"
              color="primary"
              disabled={saving}
              style={{ marginTop: '20px', marginBottom: '20px' }}
            >
              Save
            </Button>
          </form>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={10}>
              <TextField
                type="text"
                value={settings.loginMicroappUrl || ''}
                disabled
                fullWidth
                helperText="Current embedded microapp url."
              />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={this.removeEmbededUrl} component="span" variant="contained" color="primary">
                Remove
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <TextField
                type="text"
                value={settings.selfserveMicroappUrl || ''}
                disabled
                fullWidth
                helperText="Current embedded self serve microapp url."
              />
            </Grid>
          </Grid>
        </Container>
      </Container>
    );
  }
}

export default Settings;
