import React, { useState } from 'react';
import { ButtonContainer } from 'tiled-ui';
import { InputWithLabel } from './EditFormComponent';
import PermissionsPopover from './PermissionsPopover';

const UserAdminPermissionDetail = ({ value, onBlur, isTiledUser }) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const onChange = e => {
    onBlur(e);
    setIsDisabled(true);
  };

  const visibility = isTiledUser || (!isTiledUser && !!value) ? 'visible' : 'hidden';

  return (
    <InputWithLabel
      w="12"
      ml="-4%"
      label="Admin Permissions"
      value={value}
      onBlur={onChange}
      isDisabled={isDisabled}
      _disabled={{ border: 'none', backgroundColor: 'transparent' }}
    >
      <ButtonContainer
        ml="-40%"
        variant="textOnly"
        textStyle="textXXS"
        onClick={() => setIsDisabled(!isDisabled)}
        visibility={visibility}
      >
        Edit
      </ButtonContainer>
      <PermissionsPopover triggerLabel="View" adminPermissions={value} showOnlyAdmin visibility={visibility} />
    </InputWithLabel>
  );
};

export default UserAdminPermissionDetail;
