import React from 'react';
import _ from 'lodash';

import { VStack, TextContainer, Box } from 'tiled-ui';
import KeyValue from './KeyValue';

const AddConfigPreview = ({ values, name }) => {
  return _.isEmpty(values) ? null : (
    <VStack spacing="1" alignItems="flex-start">
      <TextContainer textStyle="textSm" fontWeight="bold">
        Preview
      </TextContainer>
      <Box bg="gray.10" border="1px solid" borderColor="gray.20" borderRadius="round" p="3" w="100%">
        <KeyValue dataKey={name} value={values} previewMode />
      </Box>
    </VStack>
  );
};

export default AddConfigPreview;
