import React from 'react';
import { FormControl, TextField } from '@mui/material';

const DateField = props => {
  const { id, label, value, onChange = () => {}, disabled = true } = props;

  return (
    <FormControl fullWidth>
      <TextField
        InputLabelProps={{ shrink: true }}
        type="date"
        disabled={disabled}
        id={`${id}-date-field`}
        margin="normal"
        variant="outlined"
        size="small"
        value={value}
        onChange={onChange}
        label={label}
        onKeyDown={e => e.preventDefault()}
      />
    </FormControl>
  );
};

export default DateField;
