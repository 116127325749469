import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ExpandMoreOutlined, Info as InfoIcon } from '@mui/icons-material';

import HubSettings from './HubSettings';
import DefaultTagManagement from './DefaultTagManagement';

import './index.css';

const HUB = 'hub';
const TAG = 'tag';

const Settings = props => {
  const { showSnack } = props;
  const [expanded, setExpanded] = useState();

  const handleExpand = settings => {
    if (expanded === settings) setExpanded(null);
    else setExpanded(settings);
  };
  return (
    <div>
      <p className="info-box">
        <InfoIcon className="info-box-icon" />
        These settings affect the Hub. Unless you know you should be changing them, please don't.
      </p>

      <Accordion expanded={expanded === HUB} onChange={() => handleExpand(HUB)}>
        <AccordionSummary className="settings-accordion" expandIcon={<ExpandMoreOutlined />}>
          <Typography>Hub Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HubSettings showSnack={showSnack} />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === TAG} onChange={() => handleExpand(TAG)}>
        <AccordionSummary className="settings-accordion" expandIcon={<ExpandMoreOutlined />}>
          <Typography>Default Tag Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DefaultTagManagement showSnack={showSnack} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Settings;
