import React, { useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';

import './Audit.css';
import DetailLog from './LogDetail';
import { logTableColumns, logTableStyle } from './lib/constants';

const LogTable = props => {
  const { rows, rowCount, paginationModel, setPaginationModel, isLoading, limitSize } = props;
  const [selectedLog, setSelectedLog] = useState({});

  return (
    <>
      <DataGrid
        columnHeaderHeight={44}
        rowHeight={44}
        disableColumnMenu
        hideFooterSelectedRowCount
        getRowId={row => row._id}
        columns={logTableColumns}
        rows={rows}
        rowCount={rowCount}
        pagination
        pageSizeOptions={[limitSize]}
        loading={isLoading}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onRowClick={rows => setSelectedLog(rows.row)}
        sx={logTableStyle}
      />
      {selectedLog._id && <DetailLog log={selectedLog} onClose={() => setSelectedLog({})} />}
    </>
  );
};

export default LogTable;
