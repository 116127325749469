import React, { useState, useEffect } from 'react';
import { Spinner, Flex, Box } from 'tiled-ui';
import moment from 'moment';
import _ from 'lodash';

import permissions from 'mosaic/permissions';
import UserLibraries from './UserLibraries';
// import { UpdateToAdmin } from './UserRoleUpdateModal';
import { InputWithLabel, SwitchWithLabel } from './EditFormComponent';
import UserAdminPermissionDetail from './UserAdminPermissionDetail';
import api from '../api';
import { TOOLTIP } from '../util/constants';

const formatDate = dateTime => moment(dateTime).format('MMMM DD YYYY, h:mm:ss a');

const UserDetails = props => {
  const { user: selectedUser, isDisabled = true, showSnack = () => {} } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [user, setUser] = useState(selectedUser);
  const [account, setAccount] = useState(null);
  // const [adminRole, setAdminRole] = useState(null);
  const [roles, setRoles] = useState(null);
  const [groups, setGroups] = useState(null);

  // const [showUpdateAdminRole, setShowUpdateAdminModal] = useState(false);

  const getData = async accountId => {
    try {
      const [account] = await api.accounts.getAll({ _id: accountId });
      const roleOptions = account.roles.map(role => ({ text: role.name, value: role._id }));
      const groupOptions = account.groups.map(group => ({ text: group.name, value: group.path }));

      setAccount(account);
      setRoles(roleOptions);
      setGroups(groupOptions);
    } catch (err) {
      showSnack(`Error fetching: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData(user.accountId);
  }, []);

  const isTiledUser = _.endsWith(user.email, '@tiled.co');

  const onUpdateUser = async (key, e, notificationChange = false) => {
    const field = notificationChange ? `notifications.${key}` : key;
    const originalValue = _.get(user, field);

    try {
      const value = e.target.value || e.target.checked;
      if (originalValue === value) return;

      let data = { [key]: value };
      if (notificationChange) {
        const notificationPrefs = _.assign({}, prefs, data);
        data = { notificationPrefs };
      }

      if (key === 'adminPermissions' && value > 0 && !isTiledUser) {
        showSnack('You cannot give admin permissions to outside the organization.');
        return;
      }

      const updatedUser = _.assign({}, user, data);
      await api.users(user._id).patch(data);

      setUser(updatedUser);
      showSnack(`Updated "${key}"`);
    } catch (err) {
      showSnack(`Error updating: ${err.message}`);
    }
  };

  const {
    _id,
    name,
    email,
    accountId,
    createdAt,
    dateVerified,
    lastLogin,
    resetPasswordExpires,
    joinSlug,
    defaultMessage,
    notificationPrefs: prefs,
    profileImageUrl,
    phone,
    hsContactId,
    microappLibraries,
    adminPermissions
  } = user;

  const isUserAccountAdmin = permissions.isAccountAdmin(user);

  const accountExists = !!account;
  const accountName = accountExists ? account.name : 'This user is not in any account.';
  const accountType = accountExists ? account.type.toUpperCase() : '';

  return isLoading ? (
    <Flex minH="48" alignItems="center" justifyContent="center">
      <Spinner size="lg" />
    </Flex>
  ) : (
    <Box spacing="3" maxHeight="80vh" overflow="scroll">
      <UserAdminPermissionDetail
        onBlur={onUpdateUser.bind(this, 'adminPermissions')}
        value={adminPermissions}
        isTiledUser={isTiledUser}
      />

      <InputWithLabel label="Name" isDisabled={isDisabled} value={name} onBlur={onUpdateUser.bind(this, 'name')} />
      <InputWithLabel label="Email" isDisabled={isDisabled} value={email} onBlur={onUpdateUser.bind(this, 'email')} />
      <InputWithLabel label="Account Name" isDisabled value={accountName} />
      <InputWithLabel label="Account Type" isDisabled value={accountType} />
      <InputWithLabel label="Account ID" isDisabled value={accountId} onBlur={onUpdateUser.bind(this, 'accountId')} />

      <SwitchWithLabel
        label="Is Account Admin"
        tooltipLabel={TOOLTIP.def.ACCOUNT_ADMIN}
        isDisabled
        value={isUserAccountAdmin}
        // onChange={() => setShowUpdateAdminModal(true)}
      />

      <UserLibraries
        microappLibraries={microappLibraries || []}
        groups={groups}
        roles={roles}
        isDisabled={isDisabled}
      />

      <InputWithLabel label="Created At" isDisabled value={formatDate(createdAt)} />
      <InputWithLabel label="Date Verified" isDisabled value={formatDate(dateVerified)} />
      <InputWithLabel label="Last Login" isDisabled value={formatDate(lastLogin)} />
      <InputWithLabel label="Reset Password Expires" isDisabled value={formatDate(resetPasswordExpires)} />
      <InputWithLabel
        label="Join Slug"
        tooltipLabel={TOOLTIP.def.JOIN_SLUG}
        isDisabled={isDisabled}
        value={joinSlug}
        onBlur={onUpdateUser.bind(this, 'joinSlug')}
      />
      <InputWithLabel
        label="Default Message"
        tooltipLabel={TOOLTIP.def.DEFAULT_MESSAGE}
        isDisabled={isDisabled}
        value={defaultMessage}
        onBlur={onUpdateUser.bind(this, 'defaultMessage')}
      />
      <SwitchWithLabel
        isDisabled={isDisabled}
        value={prefs.sessionNotifications}
        label="Session Notifcations"
        onChange={e => onUpdateUser('sessionNotifications', e, true)}
      />
      <SwitchWithLabel
        isDisabled={isDisabled}
        value={prefs.reminderNotifications}
        label="Reminder Notifications"
        onChange={e => onUpdateUser('reminderNotifications', e, true)}
      />
      <SwitchWithLabel
        isDisabled={isDisabled}
        value={prefs.publishNotifications}
        label="Publish Notifcations"
        onChange={e => onUpdateUser('publishNotifications', e, true)}
      />
      <SwitchWithLabel
        isDisabled={isDisabled}
        value={prefs.feedbackNotifications}
        label="Feedback Notifications"
        onChange={e => onUpdateUser('feedbackNotifications', e, true)}
      />
      <InputWithLabel label="Has Profile Image" isDisabled value={!!profileImageUrl} />
      <InputWithLabel label="Phone" isDisabled value={phone} />
      <InputWithLabel label="Hubspot ID" isDisabled value={hsContactId} />

      {/* {showUpdateAdminRole && (
        <UpdateToAdmin
          user={user}
          account={account}
          adminRole={adminRole}
          onClose={() => setShowUpdateAdminModal(false)}
          showSnack={showSnack}
          callback={setUser}
        />
      )} */}
    </Box>
  );
};

export default UserDetails;
