import _ from 'lodash';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { Permissions, UserPermissions } from 'mosaic/enums';
import mosaicUtil from 'mosaic/util';
import permissions from 'mosaic/permissions';

import api, { storage } from './api';

import './App.css';
import { ButtonContainer, InputContainer, VStack } from 'tiled-ui';

const USER_BUILDER_PERMS = mosaicUtil.permissionsValue(
  _.pick(UserPermissions.USER, [
    Permissions.READ_ORG,
    Permissions.HUB_ACCESS,
    Permissions.CD_CAMPAIGN,
    Permissions.UPDATE_CAMPAIGN,
    Permissions.PUBLISH_CAMPAIGN,
    Permissions.SUBSCRIBE,
    Permissions.ASSIGN,
    Permissions.ANALYTICS,
    Permissions.CUSTOMER_ANALYTICS,
    Permissions.PUBLISH_TEMPLATE,
    Permissions.CD_INSTANCE,
    Permissions.UPDATE_INSTANCE
  ])
);

function Login({ showSnack, onLogin }) {
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    success: !!storage.local.get('user')
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSignIn = async () => {
    try {
      const user = await api.auth.user.post({ email: values.email, password: values.password });
      if (
        !permissions.canAdminCreateDeleteAccount(user) ||
        !permissions.canUpdateAccount(user) ||
        !permissions.canAdminCreateDeleteUser(user)
      ) {
        showSnack("User doesn't have the required permissions to login");
      } else {
        await storage.local.set('user', user);
        // console.log(user);
        setValues({ ...values, success: true });
        onLogin();
      }
    } catch (err) {
      console.log('error!');
      showSnack(err.message);
    }
  };

  const keyPress = e => {
    if (e.keyCode === 13) {
      handleSignIn();
    }
  };

  if (values.success) {
    return <Redirect to="/tools" />;
  }

  return (
    <form noValidate autoComplete="off" style={{marginTop: '100px', width: '250px'}}>
      <VStack>
        <InputContainer id="email" label="Email" placeholder="Email" onChange={handleChange('email')} autoFocus/>
        <InputContainer
          type="password"
          id="password"
          label="Password"
          placeholder="Password"
          onChange={handleChange('password')}
          onKeyDown={keyPress}
        />
        <ButtonContainer onClick={handleSignIn}>Sign In</ButtonContainer>
      </VStack>
    </form>
  );
}

export default Login;
