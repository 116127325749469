import React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import SearchField from '../components/Form/SearchField';
import SelectField from '../components/Form/SelectField';
import Checkbox from '../components/Form/CheckBox';

const TYPE_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Champion', value: 'ch' },
  { label: 'Creative', value: 'c' },
  { label: 'Designer', value: 'd' },
  { label: 'Enterprise', value: 'e' },
  { label: 'Organization', value: 'o' },
  { label: 'Seismic', value: 's' },
  { label: 'Team', value: 't' },
  { label: 'Trial', value: 'sst' }
];

const AccountTableFilter = props => {
  const { onSetFilter, filter } = props;

  const setAccountQuery = e => {
    const { value } = e.target;

    if (value.match(/^[0-9a-fA-F]{24}$/)) onSetFilter('_id', value);
    else onSetFilter('name', value);
  };

  const setTypeFilter = option => {
    const newValue = option.value;
    if (!newValue) return onSetFilter('type', null);

    const previousFilter = filter.type || [];
    let newFilter;
    if (previousFilter.includes(newValue)) {
      newFilter = previousFilter.filter(f => f !== newValue);
    } else {
      if (previousFilter.length === 8) return onSetFilter('type', null);
      newFilter = [...previousFilter, newValue];
    }

    onSetFilter('type', newFilter);
  };

  return (
    <Box display="flex" flexDirection="row" paddingTop="30px" alignItems="center" gap="20px">
      <SearchField
        label="Search for accounts by id or name"
        onChange={_.debounce(setAccountQuery.bind(this), 750)}
        sx={{ width: '350px' }}
      />

      <SelectField
        multiple
        label="Type"
        sx={{ width: '300px', m: 0 }}
        value={filter.type || []}
        options={TYPE_OPTIONS}
        disabled={false}
        onSelect={setTypeFilter}
      />

      <Checkbox label="Disabled accounts" onChange={e => onSetFilter('disabled', e.target.checked)} />
      <Checkbox label="NOT Multi-Library" onChange={e => onSetFilter('nonML', e.target.checked)} />
    </Box>
  );
};

export default AccountTableFilter;
