import React, { useState } from 'react';
import { FormControl, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchField = props => {
  const { id, label, value, onChange = () => {}, disabled = false, ...rest } = props;
  // const [newValue, setNewValue] = useState('');

  return (
    <FormControl fullWidth {...rest}>
      <TextField
        type="search"
        disabled={disabled}
        id={`${id}-input-field`}
        label={label}
        size="small"
        value={value}
        onChange={onChange}
        InputLabelProps={{ sx: { bgcolor: 'white', paddingRight: '8px' } }}
        InputProps={{
          sx: { fontSize: '13px' },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="10px" />
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};

export default SearchField;
