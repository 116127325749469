import React from 'react';

import permissions from 'mosaic/permissions';
import { storage } from '../../api';

import { List, ListItem, ListItemText, ListSubheader, Checkbox } from '@mui/material';
import '../Account.css';

const AccountFeatures = ({ account, premiumFeatures, onUpdateAccount }) => {
  const { features = [] } = account;
  const user = storage.local.get('user');
  const disabled = !permissions.canAdminUpdateAccount(user);

  const onChange = async (featureId, e) => {
    const { checked } = e.target;
    const newFeatures = checked ? [...features, featureId] : features.filter(f => f !== featureId);
    await onUpdateAccount({ features: newFeatures });
  };

  return (
    <List dense className="list">
      <ListSubheader align="left" className="list-sub-header">
        Premium Features
      </ListSubheader>

      {premiumFeatures.map(feature => {
        const { title, _id: featureId } = feature;
        const isEnabled = features.includes(featureId);

        return (
          <ListItem key={featureId} disableGutters className="list-item-features">
            <Checkbox
              size="small"
              color="primary"
              checked={isEnabled}
              disabled={disabled}
              onChange={onChange.bind(this, featureId)}
            />
            <ListItemText primary={title} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default AccountFeatures;
