import React from 'react';
import { Flex, InputContainer, TextContainer, SwitchContainer, TooltipContainer } from 'tiled-ui';

export const InputWithLabel = props => {
  const { label, value, onBlur, tooltipLabel, labelColor = 'gray.80', children, ...rest } = props;
  return (
    <Flex key={Math.random()} id={`user-${label}`} alignItems="center" my="2">
      <TextContainer w="25%" textStyle="textXS" fontWeight="medium" color={labelColor}>
        {label}
        {tooltipLabel && (
          <TooltipContainer variant="darkOver" size="xs" label={tooltipLabel}>
            <sup style={{ color: 'slategray', fontSize: '9px' }}>ℹ</sup>
          </TooltipContainer>
        )}
      </TextContainer>

      <InputContainer
        ml="2"
        w="90%"
        onBlur={onBlur}
        onKeyUp={e => {
          e.preventDefault();
          if (e.key === 'Enter') onBlur(e);
        }}
        value={value}
        isTruncated
        _disabled={{ color: 'black' }}
        {...rest}
      />
      {children}
    </Flex>
  );
};

export const SwitchWithLabel = props => {
  const { label, value, onChange, tooltipLabel, isDisabled, ...rest } = props;
  return (
    <Flex key={Math.random()} id={`user-${label}`} alignItems="center" minHeight="9" my="2" w="100%" {...rest}>
      <TextContainer w="20%" textStyle="textXS" fontWeight="medium" color="gray.80">
        {label}
        {tooltipLabel && (
          <TooltipContainer variant="darkOver" size="xs" label={tooltipLabel}>
            <sup style={{ color: 'slategray', fontSize: '9px' }}>ℹ</sup>
          </TooltipContainer>
        )}
      </TextContainer>
      <SwitchContainer ml="2" size="sm" isDisabled={isDisabled} {...rest} defaultChecked={value} onChange={onChange} />
    </Flex>
  );
};
