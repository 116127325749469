import React, { useState } from 'react';
import permissions from 'mosaic/permissions';

import { Box, Typography, ListItem, Checkbox } from '@mui/material';
import { Edit } from '@mui/icons-material';

import ListComponent, { ListSelectComponent } from '../../components/ListComponent';
import '../Account.css';

import EditAccount from './EditAccount';

import { storage } from '../../api';

const accountKeys = {
  _id: null,
  name: null,
  type: null,
  autoProvisioned: null,
  allowAutoProvisioning: null,
  enableSSO: null,
  forceSSO: null,
  signupData: value => JSON.stringify(value, null, 2),
  createdAt: null,
  updatedAt: null,
  disabledAt: null
};

const libraryKeys = {
  name: null,
  joinSlug: null,
  emailLogoUrl: null,
  welcomeEmail: value => JSON.stringify(value),
  banner: value => value && JSON.stringify({ title: value.title, url: !!value.url }),
  apiTokens: value => value.length,
  replyTo: value => JSON.stringify(value),
  defaultInternalOnly: null,
  defaultPresenterOnlyMode: null,
  customLanding: value => JSON.stringify(value, null, 2),
  privacy: value => value && JSON.stringify({ requireConsent: value.requireConsent }),
  globalIntegrations: value => {
    const integrations = (value || []).map(integration => {
      return {
        enabled: !!integration.enabled,
        name: integration.name,
        type: integration.type,
        appId: integration.data.appId,
        createdAt: integration.createdAt,
        updatedAt: integration.updatedAt,
        deletedAt: integration.deletedAt
      };
    });
    return JSON.stringify(integrations, null, 2);
  }
};

const AccountInfo = ({ account, onDisableAccount, onUpdateAccount }) => {
  const [selectedLibrary, setSelectedLibrary] = useState({});
  const [isEditAccountDialogOpen, setIsEditAccountDialogOpen] = useState(false);

  const user = storage.local.get('user');

  const canUserUpdateAccount = permissions.canAdminUpdateAccount(user);

  const subHeader = () => {
    return (
      <>
        Account Info
        {canUserUpdateAccount && <Edit fontSize="small" onClick={() => setIsEditAccountDialogOpen(true)} />}
      </>
    );
  };

  return (
    <Box display="flex">
      <ListComponent subHeader={subHeader} listItemMap={accountKeys} data={account || {}}>
        <AccountLibraries
          libraries={account.libraries}
          selectedLibrary={selectedLibrary}
          setSelectedLibrary={setSelectedLibrary}
        />

        <br />

        <Box className="disable-account">
          <Checkbox
            size="small"
            color="secondary"
            checked={!!account.disabledAt}
            disabled={!canUserUpdateAccount}
            sx={{ cursor: canUserUpdateAccount ? 'pointer' : 'not-allowed' }}
            onChange={onDisableAccount}
          />
          <Typography variant="subtitle2">
            {account.disabledAt ? `Account disabled on ${account.disabledAt}` : 'Disable account'}
          </Typography>
        </Box>
      </ListComponent>

      {selectedLibrary._id && <LibraryInfo selectedLibrary={selectedLibrary} />}
      <EditAccount
        account={account}
        onUpdateAccount={onUpdateAccount}
        open={isEditAccountDialogOpen}
        onClose={() => setIsEditAccountDialogOpen(false)}
      />
    </Box>
  );
};

const AccountLibraries = ({ libraries, selectedLibrary, setSelectedLibrary }) => {
  return (
    <ListItem style={{ display: 'block' }}>
      <div className="list-item-detail-key">Microapp libraries:</div>

      <ListSelectComponent
        itemList={libraries}
        selectedItem={selectedLibrary}
        onSelect={setSelectedLibrary}
        displayKey="name"
      />
    </ListItem>
  );
};

const LibraryInfo = ({ selectedLibrary }) => {
  return (
    <ListComponent
      subHeader="Library Info"
      listItemMap={libraryKeys}
      data={selectedLibrary || {}}
      style={{ maxWidth: '650px' }}
    />
  );
};

export default AccountInfo;
