export const DELETED = 'Deleted';

export const logTableStyle = {
  padding: '12px',
  boxShadow: 2,
  cursor: 'pointer',
  overflow: 'scroll',
  '.MuiDataGrid-main': { height: '525px', overflow: 'scroll' },
  '.MuiDataGrid-footerContainer': { background: '#F1F1F1', minHeight: '44px' },
  '.Mui-selected': { color: 'primary.main', fontWeight: 'bold' },
  '& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeaders:focus': { outline: 'none' },
  '.MuiDataGrid-columnHeaders': { position: 'sticky', top: 0, zIndex: 'appBar' },
  '.MuiToolbar-root': { minHeight: '44px' }
};

export const logTableColumns = [
  {
    field: 'actorEmail',
    headerName: 'Actor',
    sortable: false,
    minWidth: 250,
    flex: 1,
    headerClassName: 'column-header'
  },
  {
    field: 'assumedRole',
    headerName: 'Assumed',
    sortable: false,
    minWidth: 40,
    flex: 1,
    headerClassName: 'column-header'
  },
  { field: 'method', headerName: 'Method', sortable: false, minWidth: 40, flex: 1, headerClassName: 'column-header' },
  {
    field: 'requestUrl',
    headerName: 'Request URL',
    sortable: false,
    minWidth: 260,
    flex: 1,
    headerClassName: 'column-header'
  },
  {
    field: 'affectedAccountName',
    headerName: 'Account Name',
    sortable: false,
    minWidth: 250,
    flex: 1,
    headerClassName: 'column-header'
  },
  {
    field: 'affectedAccountId',
    headerName: 'Account ID',
    sortable: false,
    minWidth: 220,
    flex: 1,
    headerClassName: 'column-header'
  },
  { field: 'timestamp', headerName: 'Timestamp', minWidth: 225, flex: 1, headerClassName: 'column-header' }
];
