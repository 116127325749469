import React from 'react';
import _ from 'lodash';
import { Box, Divider, Drawer, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { DELETED } from './lib/constants';
import { actorKeys, metadataKeys, eventKeys } from './lib/util';

const Item = props => {
  const { property, value } = props;
  const fontStyle = value === DELETED ? 'italic' : 'normal';

  if (!value) return null;

  return (
    <Box marginTop="10px">
      <Typography variant="caption" fontWeight="bold">
        {_.startCase(property).toUpperCase()}
      </Typography>
      <Typography variant="subtitle2" fontSize="13px" fontWeight="normal" fontStyle={fontStyle}>
        {value}
      </Typography>
    </Box>
  );
};

const Header = ({ onClose }) => {
  return (
    <Box
      top={0}
      position="sticky"
      padding="12px 16px"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      bgcolor="#EDEDED"
      alignItems="center"
    >
      <Typography variant="subtitle" fontWeight="bold">
        DETAIL LOG
      </Typography>
      <Close sx={{ '&:hover': { color: 'primary.main' } }} cursor="pointer" onClick={onClose} />
    </Box>
  );
};

const Details = props => {
  const { log } = props;

  return (
    <Box padding="0px 16px">
      {/* who */}
      <Item property="assumedRole" value={log.assumedRole} />
      <Box display="grid" gridAutoFlow="column" gridTemplateRows="repeat(5, auto)">
        {actorKeys.map(actorKey => {
          const v = log[actorKey];
          return <Item key={actorKey} property={actorKey} value={v} />;
        })}
      </Box>

      <Divider sx={{ height: '8px' }} />

      {/* what */}
      <Box display="grid" gridAutoFlow="column" gridTemplateRows="repeat(4, auto)">
        <Item property="action" value={log.actionType} />
        {eventKeys.map(eventKey => {
          const v = log.action?.[eventKey] || log[eventKey];
          return <Item key={eventKey} property={eventKey} value={v} />;
        })}
      </Box>

      <Divider sx={{ height: '8px' }} />

      {/* metadata */}
      <Box display="grid" gridAutoFlow="column" gridTemplateRows="repeat(3, auto)">
        {metadataKeys.map(actorsKey => {
          const v = log[actorsKey];
          return <Item key={actorsKey} property={actorsKey} value={v} />;
        })}
      </Box>
    </Box>
  );
};

const LogDetail = props => {
  const { onClose, log } = props;
  return (
    <Drawer open anchor="right" onClose={onClose}>
      <Box width="40vw" overflow="scroll">
        <Header onClose={onClose} />
        <Details log={log} />
      </Box>
    </Drawer>
  );
};

export default LogDetail;
