import React, { useState } from 'react';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, TextField } from '@mui/material';
import { DeleteOutline, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import moment from 'moment';

import './TagList.css';
import { sortByKey } from '../util';

const ASCENDING = 'asc';
const DESCENDING = 'desc';

const NAME = 'name';
const CREATED_AT = 'createdAt';

const TagTable = ({ tags, onDelete }) => {
  const [search, setSearch] = useState('');
  const [sortKey, setSortKey] = useState(NAME);
  const [sortDir, setSortDir] = useState(ASCENDING);

  const onSortChange = key => {
    if (key !== sortKey) {
      setSortKey(key);
      setSortDir(ASCENDING);
    } else {
      const newDir = sortDir === ASCENDING ? DESCENDING : ASCENDING;
      setSortDir(newDir);
    }
  };

  const sortIcon =
    sortDir === ASCENDING ? <KeyboardArrowUp className="icon" /> : <KeyboardArrowDown className="icon" />;

  const sortedTags = search
    ? sortByKey(
        tags.filter(tag => tag.name.includes(search)),
        sortKey,
        sortDir
      )
    : sortByKey(tags, sortKey, sortDir);

  return (
    <TableContainer style={{ display: 'grid' }}>
      <TextField
        className="tag-search"
        size="small"
        onChange={e => setSearch(e.target.value)}
        label="Search tags"
        variant="outlined"
      />

      <Table aria-label="tag table" className="tag-table">
        <TableHead>
          <TableRow>
            <TableCell className="tag-table-header" sx={{ width: '75%' }} onClick={() => onSortChange(NAME)}>
              Name {sortKey === NAME && sortIcon}
            </TableCell>
            <TableCell className="tag-table-header" align="right" onClick={() => onSortChange(CREATED_AT)}>
              Created on {sortKey === CREATED_AT && sortIcon}
            </TableCell>
            <TableCell align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTags.map(tag => (
            <TableRow key={tag._id}>
              <TableCell component="th" scope="row">
                {tag.name}
              </TableCell>
              <TableCell align="right">{moment(tag.createdAt).format('YYYY-MM-DD')}</TableCell>
              <TableCell align="right">
                <DeleteOutline className="icon" onClick={() => onDelete(tag._id)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TagTable;
