import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Logo, LogoVariants, Box, HStack } from 'tiled-ui';
import './Nav.css';
import permissions from 'mosaic/permissions';

const Nav = ({ name, loggedIn, onSignOut, user }) => {
  const showSuper = permissions.isSuperUser(user);

  return (
    <div className="nav">
      <div className="Nav-content">
        <HStack>
          <Box paddingX="6">
            <Logo variant={LogoVariants.onlyLogo} size={10} />
          </Box>
          <NavLink activeClassName="Nav-active" className="Nav-link" to="/tools">
            Tools
          </NavLink>
          <NavLink activeClassName="Nav-active" className="Nav-link" to="/settings">
            Settings
          </NavLink>
          <NavLink activeClassName="Nav-active" className="Nav-link" to="/accounts">
            All Accounts
          </NavLink>
          {showSuper && (
            <>
              <NavLink activeClassName="Nav-active" className="Nav-link" to="/super">
                Super
              </NavLink>
              <NavLink activeClassName="Nav-active" className="Nav-link" to="/audit">
                Audit
              </NavLink>
            </>
          )}
        </HStack>

        <div className="Nav-logout">
          {loggedIn && (
            <Fragment>
              <div className="Nav-logout" onClick={onSignOut}>
                Sign {name} out <ExitToAppIcon className="Nav-logout-icon" fontSize="small" />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

Nav.propTypes = {
  name: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool,
  onSignOut: PropTypes.func
};

export default Nav;
